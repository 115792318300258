import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/vendor/flaticon.css";
import "./css/vendor/fontawesome.min.css";
import "./css/vendor/themify-icons.css";

import Home from "./pages/Home";

import About_us from "./pages/About_us";
import Services from "./pages/Services";
import Services_details from "./pages/Services_details";

import Blog_details from "./pages/Blog_details";
import Contact_us from "./pages/Contact_us";
import EuropeWorkVisa from "./pages/EuropeWorkVisa";

import Job_list from "./pages/Job_list";
import Job_details from "./pages/Job_details";
import Carrer from "./pages/Carrer";
import Error_404 from "./pages/Error_404";

import Login from "./pages/Login";

import ScrollToTop from "./components/layout/Gotop";
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <div className="page">
      <Router>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/"}`}
          component={Home}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/About_us"}`}
          component={About_us}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Services"}`}
          component={Services}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Services_details"}`}
          component={Services_details}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Contact_us"}`}
          component={Contact_us}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Europe-Work-Visa"}`}
          component={EuropeWorkVisa}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Job_list"}`}
          component={Job_list}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Job_details"}`}
          component={Job_details}
        />

        <Route
          path={`${process.env.PUBLIC_URL + "/Login"}`}
          component={Login}
        />

        <ScrollToTop />
      </Router>
      <CookieConsent enableDeclineButton flipButtons>
        This website uses cookies to enhance the user experience. <br></br>{" "}
        <br></br>
        <a
          rel="noreferrer"
          className="developerinfo"
          target="_blank"
          href="https://www.linkedin.com/in/sajeeb-ahmed/"
          style={{
            backgroundColor: "rgb(5 33 39)",
            color: "white",
            textDecoration: "none",
            padding: "8px 16px",
            borderRadius: "4px",
            display: "inline-block",
            cursor: "pointer",
          }}
        >
          Contact With Developer
        </a>
      </CookieConsent>
    </div>
  );
}

export default App;
