import React, { Component } from "react";
import Slider from "react-slick";
import ProgressBar from "react-animated-progress-bar";
import Header from "../components/layout/Header2";
import { Banner } from "../components/banner/Home3_banner";
import { Footer } from "../components/layout/Footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CountUp from "react-countup";
import Video from "../components/layout/Video";
import Img from "./Img";
import backgroundImage from "../images/images/vandari.jpg";
import HappyClientsBackgroundImage from "../images/slides/05.jpg";
import euImage from "../images/slides/euf.jpg";
import agricultureImage from "../images/slides/agriculturebg.jpg";
import driverImage from "../images/images/driver.jpg";
import welderImage from "../images/images/welder.jpg";
import constImage from "../images/images/const.jpg";
import work1 from "../images/images/work1.jpg";
import work2 from "../images/images/work2.jpg";
import work3 from "../images/images/work3.jpg";
import work4 from "../images/images/work4.jpg";
import ClientsSlider from "./ClientsSlider";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";

const sliderElements = [
  {
    id: 1,
    description: (
      <div className="col-lg-12">
        {/* testimonials */}
        <div
          className="testimonials ttm-testimonial-box-view-style2"
          role="group"
        >
          <div className="testimonial-top">
            <div className="testimonial-avatar">
              <div className="testimonial-img">
                <Img
                  className="img-fluid"
                  src="images/slides/01.jpg"
                  alt="testimonial-img"
                />
              </div>
            </div>
            <div className="testimonial-caption">
              <label>Electrician</label>
              <h3>Asif H. </h3>
            </div>
          </div>
          <div className="testimonial-content">
            <blockquote className="testimonial-text">
              "Workwell Solutions BD helped me secure a fantastic job in Europe.
              Their team guided me throughout the process, and their support was
              invaluable. I am now settled and enjoying my new life. Thank you,
              Workwell Solutions BD!"
            </blockquote>
          </div>
        </div>
        {/* testimonials end */}
      </div>
    ),
  },
  {
    id: 2,
    description: (
      <div className="col-lg-12">
        {/* testimonials */}
        <div
          className="testimonials ttm-testimonial-box-view-style2"
          role="group"
        >
          <div className="testimonial-top">
            <div className="testimonial-avatar">
              <div className="testimonial-img">
                <Img
                  className="img-fluid"
                  src="images/slides/08.jpg"
                  alt="testimonial-img"
                />
              </div>
            </div>
            <div className="testimonial-caption">
              <label>Refrigeration Technicians</label>
              <h3>Anamul Haque </h3>
            </div>
          </div>
          <div className="testimonial-content">
            <blockquote className="testimonial-text">
              "I couldn't have found my dream job as a Refrigeration Technicians
              in Europe without Workwell Solutions BD. Their expertise and
              dedication made the entire process smooth and stress-free. I
              highly recommend their services to anyone seeking a job abroad."
            </blockquote>
          </div>
        </div>
        {/* testimonials end */}
      </div>
    ),
  },
  {
    id: 3,
    description: (
      <div className="col-lg-12">
        {/* testimonials */}
        <div
          className="testimonials ttm-testimonial-box-view-style2"
          role="group"
        >
          <div className="testimonial-top">
            <div className="testimonial-avatar">
              <div className="testimonial-img">
                <Img
                  className="img-fluid"
                  src="images/slides/02.jpg"
                  alt="testimonial-img"
                />
              </div>
            </div>
            <div className="testimonial-caption">
              <label>Plumber</label>
              <h3> Rahim M. </h3>
            </div>
          </div>
          <div className="testimonial-content">
            <blockquote className="testimonial-text">
              "Workwell Solutions BD provided exceptional support during my job
              search as a Plumber. Their training programs and pre-departure
              assistance prepared me for a successful transition to Europe. I am
              grateful for their help and support."
            </blockquote>
          </div>
        </div>
        {/* testimonials end */}
      </div>
    ),
  },
  {
    id: 4,
    description: (
      <div className="col-lg-12">
        {/* testimonials */}
        <div
          className="testimonials ttm-testimonial-box-view-style2"
          role="group"
        >
          <div className="testimonial-top">
            <div className="testimonial-avatar">
              <div className="testimonial-img">
                <Img
                  className="img-fluid"
                  src="images/slides/03.jpg"
                  alt="testimonial-img"
                />
              </div>
            </div>
            <div className="testimonial-caption">
              <label>Restaurant Manager</label>
              <h3>Nasrin S. </h3>
            </div>
          </div>
          <div className="testimonial-content">
            <blockquote className="testimonial-text">
              "The team at Workwell Solutions BD went above and beyond to ensure
              that I found the perfect job as a Restaurant Manager in Europe.
              Their post-arrival support helped me integrate into my new
              community and made the entire experience enjoyable. I can't thank
              them enough!"
            </blockquote>
          </div>
        </div>
        {/* testimonials end */}
      </div>
    ),
  },
  {
    id: 5,
    description: (
      <div className="col-lg-12">
        {/* testimonials */}
        <div
          className="testimonials ttm-testimonial-box-view-style2"
          role="group"
        >
          <div className="testimonial-top">
            <div className="testimonial-avatar">
              <div className="testimonial-img">
                <Img
                  className="img-fluid"
                  src="images/slides/04.jpg"
                  alt="testimonial-img"
                />
              </div>
            </div>
            <div className="testimonial-caption">
              <label>Welder</label>
              <h3>Imran A. </h3>
            </div>
          </div>
          <div className="testimonial-content">
            <blockquote className="testimonial-text">
              "As a Welder, Workwell Solutions BD helped me find a fantastic job
              opportunity in Europe. Their professionalism and support made my
              journey a success."
            </blockquote>
          </div>
        </div>
        {/* testimonials end */}
      </div>
    ),
  },
  {
    id: 6,
    description: (
      <div className="col-lg-12">
        {/* testimonials */}
        <div
          className="testimonials ttm-testimonial-box-view-style2"
          role="group"
        >
          <div className="testimonial-top">
            <div className="testimonial-avatar">
              <div className="testimonial-img">
                <Img
                  className="img-fluid"
                  src="images/slides/05.jpg"
                  alt="workwell soluations bd testimonial-img"
                />
              </div>
            </div>
            <div className="testimonial-caption">
              <label>Agricultural Worker</label>
              <h3>Mostafa Kamal </h3>
            </div>
          </div>
          <div className="testimonial-content">
            <blockquote className="testimonial-text">
              "I'm now a thriving Agricultural Worker in Europe, thanks to
              Workwell Solutions BD. Their commitment to my success made all the
              difference in my career."
            </blockquote>
          </div>
        </div>
        {/* testimonials end */}
      </div>
    ),
  },
  {
    id: 7,
    description: (
      <div className="col-lg-12">
        {/* testimonials */}
        <div
          className="testimonials ttm-testimonial-box-view-style2"
          role="group"
        >
          <div className="testimonial-top">
            <div className="testimonial-avatar">
              <div className="testimonial-img">
                <Img
                  className="img-fluid"
                  src="images/slides/06.jpg"
                  alt="testimonial-img"
                />
              </div>
            </div>
            <div className="testimonial-caption">
              <label>Driver </label>
              <h3> Omar R. </h3>
            </div>
          </div>
          <div className="testimonial-content">
            <blockquote className="testimonial-text">
              "Workwell Solutions BD played a crucial role in my success as a
              Delivery Driver in Europe. Their guidance and support made my
              experience enjoyable and rewarding."
            </blockquote>
          </div>
        </div>
        {/* testimonials end */}
      </div>
    ),
  },
  {
    id: 8,
    description: (
      <div className="col-lg-12">
        {/* testimonials */}
        <div
          className="testimonials ttm-testimonial-box-view-style2"
          role="group"
        >
          <div className="testimonial-top">
            <div className="testimonial-avatar">
              <div className="testimonial-img">
                <Img
                  className="img-fluid"
                  src="images/slides/07.jpg"
                  alt="testimonial-img"
                />
              </div>
            </div>
            <div className="testimonial-caption">
              <label>Tailors</label>
              <h3> Mohamed Riton </h3>
            </div>
          </div>
          <div className="testimonial-content">
            <blockquote className="testimonial-text">
              "As a Tailor, I found the perfect job in Europe with the help of
              Workwell Solutions BD. Their expertise and dedication to my
              success were truly invaluable."
            </blockquote>
          </div>
        </div>
        {/* testimonials end */}
      </div>
    ),
  },
  {
    id: 9,
    description: (
      <div className="col-lg-12">
        {/* testimonials */}
        <div
          className="testimonials ttm-testimonial-box-view-style2"
          role="group"
        >
          <div className="testimonial-top">
            <div className="testimonial-avatar">
              <div className="testimonial-img">
                <Img
                  className="img-fluid"
                  src="images/slides/09.jpg"
                  alt="testimonial-img"
                />
              </div>
            </div>
            <div className="testimonial-caption">
              <label>Cleaner</label>
              <h3>Akash P. </h3>
            </div>
          </div>
          <div className="testimonial-content">
            <blockquote className="testimonial-text">
              "Workwell Solutions BD supported my transition to a fulfilling job
              as a Cleaner in Europe. Their training programs and assistance
              prepared me for a successful journey."
            </blockquote>
          </div>
        </div>
        {/* testimonials end */}
      </div>
    ),
  },
  {
    id: 10,
    description: (
      <div className="col-lg-12">
        {/* testimonials */}
        <div
          className="testimonials ttm-testimonial-box-view-style2"
          role="group"
        >
          <div className="testimonial-top">
            <div className="testimonial-avatar">
              <div className="testimonial-img">
                <Img
                  className="img-fluid"
                  src="images/slides/21.jpg"
                  alt="testimonial-img"
                />
              </div>
            </div>
            <div className="testimonial-caption">
              <label>Refrigeration Technician </label>
              <h3> Fahim Z. </h3>
            </div>
          </div>
          <div className="testimonial-content">
            <blockquote className="testimonial-text">
              "I'm proud to say that Workwell Solutions BD helped me secure a
              fantastic job as a Refrigeration Technician in Europe. Their
              services were exceptional and made my dream come true!"
            </blockquote>
          </div>
        </div>
        {/* testimonials end */}
      </div>
    ),
  },
];

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      currentSlide: 0,
    };
    this.state = {
      jobTitleOrKeywords: "",
      location: "Europe",
      jobs: [
        { title: "Building & Construction Visa", location: "Romania" },
        { title: "Welders Visa", location: "Romania" },
        { title: "Plumbers Visa ", location: "CZ Repulbic" },
        { title: "Refrigeration Technicians Visa ", location: "Europe" },
        { title: "Electricians Job Visa", location: "Romania ,Europe" },
        { title: "Drivers Work Visa", location: "Malta" },
        { title: "Printers Visa", location: "Europe" },
        { title: "Agriculture Visa", location: "Romania" },
        { title: "Restaurant Visas", location: "Europe" },
        { title: "Work Visas", location: "Europe" },
        { title: "Big Payer Jobs", location: "Europe" },
        { title: "Construction Visa", location: "Europe" },
      ],
      filteredJobs: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const jobTitleOrKeywords = document.getElementById("filter").value;
    const location = document.getElementById("txtlocation").value;

    // Check if input values are empty
    if (jobTitleOrKeywords.trim() === "") {
      // alert("Please Type your dream jobs !");
      Swal.fire(
        "European Job Opportunities?",
        "We are committed to providing personalized services to our clients? Please enter your Skill Jobs Name",
        "question"
      );
    } else {
      this.filterJobs(jobTitleOrKeywords, location);
    }
  };

  filterJobs = (jobTitleOrKeywords, location) => {
    const filteredJobs = this.state.jobs.filter(
      (job) =>
        job.title.toLowerCase().includes(jobTitleOrKeywords.toLowerCase()) &&
        job.location.toLowerCase().includes(location.toLowerCase())
    );

    this.setState({ filteredJobs });
  };

  handleAfterChange = (index) => {
    console.log("after change", index);
    this.setState({
      currentSlide: index,
    });
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    const settings = {
      beforeChange: function (currentSlide, nextSlide) {
        console.log("before change", currentSlide, nextSlide);
      },
      afterChange: this.handleAfterChange,
    };
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      rows: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="site-main">
        <Helmet>
          <title> WorkWell Solutions BD -Europe Work Visa Guide </title>
          <meta
            name="description"
            content="Explore opportunities for Europe Work Visa with WorkWell Solutions BD. Learn about Romania and Poland Work Visa, Work Permit in Europe, Malta and Croatia Job Visa and more."
          />
          <meta
            name="keywords"
            content="workwell, workwell solutions, workwell solutions bd, workwellsolutionsbd, vandary, vandary faruk, europe work visa, romania work visa, poland work visa, work permit europe, malta job visa, croatia work visa, job in europe, bangladesh europe job, estonia job, workvisaeurope, jobsineurope, romania job visa, restaurant job, agriculture job visa europe, italy job visa, italy agriculture visa, work well solution bd, workwellsolution, workwellsolutionsbd, skyasiabd, skyasia, sky asia, job visa europe"
          />
        </Helmet>

        <Header />

        {/* Banner */}
        <Banner />
        {/* Banner end */}

        {/* form */}
        <div className="form-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-30 p-lg-20 mt_60 mt-xl-50">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content">
                    <form
                      id="search_Form"
                      className="search_Form wrap-form m-1 border rounded rounded-3 overflow-hidden"
                      onSubmit={this.handleSubmit}
                    >
                      <label>
                        <i className="ti ti-search"></i>
                        <input
                          type="text"
                          id="filter"
                          placeholder="Job Title or Keywords"
                        />
                      </label>
                      <label>
                        <i className="ti ti-location-pin"></i>
                        <input
                          type="text"
                          name="location"
                          id="txtlocation"
                          placeholder="Europe Union"
                          disabled
                        />
                      </label>
                      <button
                        className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                        type="submit"
                      >
                        Find Jobs
                      </button>
                    </form>

                    <div>
                      {this.state.filteredJobs.map((job, index) => (
                        <div key={index}>
                          <h3>{job.title}</h3>
                          <p>{job.location}</p>
                          <a
                            className="btn btn-dark btn-inline ttm-btn-size-md ttm-btn-color-dark"
                            href={process.env.PUBLIC_URL + "/job_list"}
                          >
                            Apply Jobs!
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* form end */}

        {/* features-section */}
        <section className="my-5 py-lg-3 features-section clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      Get{" "}
                      <span className="text-theme-SkinColor">Appropriate</span>
                    </h3>
                    <h2 className="title">Search Job By Industry</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* row */}
            <div className="row row-equal-height mb_10">
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="ttm-box-view-overlay">
                    {/* finance-hover */}
                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                      <div
                        className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                        style={{
                          backgroundImage: `url(${agricultureImage})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="layer-content"></div>
                    </div>
                    {/* finance-hover end */}
                  </div>
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                      <Img
                        className="img-fluid"
                        src="images/categories/Agriculture1.png"
                      />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Agriculture </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        50 vacancies<br></br> Farming, crop and livestock
                        management.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                      href={process.env.PUBLIC_URL + "/job_list"}
                    >
                      Apply Jobs!
                    </a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="ttm-box-view-overlay">
                    {/* technology-hover */}
                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                      <div
                        className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                        style={{
                          backgroundImage: `url(${driverImage})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="layer-content"></div>
                    </div>
                    {/* technology-hover end */}
                  </div>
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                      <Img
                        className="img-fluid"
                        src="images/categories/chauffer.png"
                      />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Driver </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        40 vacancies<br></br>Operating vehicles for
                        transportation.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                      href={process.env.PUBLIC_URL + "/job_list"}
                    >
                      Apply Jobs!
                    </a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-top-content style1 active">
                  <div className="ttm-box-view-overlay">
                    {/* agriculture-hover */}
                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                      <div
                        className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                        style={{
                          backgroundImage: `url(${constImage})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="layer-content"></div>
                    </div>
                    {/* agriculture-hover end */}
                  </div>
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                      <Img className="img-fluid" src="images/cat-icon3.png" />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Building & Construction</h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        100 Jobs<br></br>Construction site labor and project
                        management
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                      href={process.env.PUBLIC_URL + "/job_list"}
                    >
                      Apply Jobs!
                    </a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="ttm-box-view-overlay">
                    {/* construction-hover */}
                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                      <div
                        className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                        style={{
                          backgroundImage: `url(${welderImage})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="layer-content"></div>
                    </div>
                    {/* construction-hover end */}
                  </div>
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                      <Img
                        className="img-fluid"
                        src="images/categories/Welder.png"
                      />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Welding</h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        120 Jobs<br></br>Skilled metalwork and joining
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                      href={process.env.PUBLIC_URL + "/job_list"}
                    >
                      Apply Jobs!
                    </a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="ttm-box-view-overlay">
                    {/* manufacturing-hover */}
                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                      <div
                        className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                        style={{
                          backgroundImage: `url(${work1})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="layer-content"></div>
                    </div>
                    {/* manufacturing-hover end */}
                  </div>
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                      <Img
                        className="img-fluid"
                        src="images/categories/Plumber.png"
                      />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Plumbing</h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        70 Jobs<br></br>Installation and maintenance of pipes
                        and fixtures
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                      href={process.env.PUBLIC_URL + "/job_list"}
                    >
                      Apply Jobs!
                    </a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="ttm-box-view-overlay">
                    {/* production-hover */}
                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                      <div
                        className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                        style={{
                          backgroundImage: `url(${work2})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="layer-content"></div>
                    </div>
                    {/* production-hover end */}
                  </div>
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                      <Img
                        className="img-fluid"
                        src="images/categories/Electricianmain.png"
                      />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Electricianmain</h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        90 Jobs<br></br>Electrical system installation and
                        maintenance
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                      href={process.env.PUBLIC_URL + "/job_list"}
                    >
                      Apply Jobs!
                    </a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="ttm-box-view-overlay">
                    {/* transport-hover */}
                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                      <div
                        className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                        style={{
                          backgroundImage: `url(${work3})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="layer-content"></div>
                    </div>
                    {/* transport-hover end */}
                  </div>
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                      <Img
                        className="img-fluid"
                        src="images/categories/2276360.png"
                      />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Big Pair</h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        125 Jobs<br></br>Caregiving and cultural exchange
                        opportunities
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                      href={process.env.PUBLIC_URL + "/job_list"}
                    >
                      Apply Jobs!
                    </a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="ttm-box-view-overlay">
                    {/* insurance-hover */}
                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                      <div
                        className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                        style={{
                          backgroundImage: `url(${work4})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="layer-content"></div>
                    </div>
                    {/* insurance-hover end */}
                  </div>
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                      <Img
                        className="img-fluid"
                        src="images/categories/Resturant.png"
                      />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Restaurant Visa </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        110 Jobs<br></br>Food service and hospitality roles
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                      href={process.env.PUBLIC_URL + "/job_list"}
                    >
                      Apply Jobs!
                    </a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* features-section end */}
        {/* about-section */}
        <section className="ttm-row about-section clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title">
                  <div className="title-header">
                    <h3>
                      who <span className="text-theme-SkinColor">we are!</span>
                    </h3>
                    <h2 className="title">
                      Your Trusted Partner for European Job Opportunities
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      We are committed to providing personalized services to our
                      clients, understanding their unique requirements and
                      working tirelessly to meet their needs. We take pride in
                      our integrity, honesty, and transparency, ensuring that
                      our clients receive genuine and reliable work visas and
                      permits.
                    </p>
                  </div>
                </div>
                {/* section title end */}
                <div className="mb-35">
                  <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-DarkColor">
                    <li>
                      <i className="far fa-check-circle"></i>
                      <div className="ttm-list-li-content">
                        Our team conducts a consultation to understand our
                        clients' needs and requirements, providing guidance on
                        the job market and visa options.
                      </div>
                    </li>
                    <li>
                      <i className="far fa-check-circle"></i>
                      <div className="ttm-list-li-content">
                        We assess our clients' qualifications, skills, and work
                        experience to determine their eligibility for job
                        opportunities in Europe.
                      </div>
                    </li>
                    <li>
                      <i className="far fa-check-circle"></i>
                      <div className="ttm-list-li-content">
                        We have 90% best strategies that get the best suitable
                        candidates
                      </div>
                    </li>
                    <li>
                      <i className="far fa-check-circle"></i>
                      <div className="ttm-list-li-content">
                        We search for suitable job openings in our extensive
                        network of employers in various European countries,
                        matching our clients' skills and qualifications.
                      </div>
                    </li>
                    <li>
                      <i className="far fa-check-circle"></i>
                      <div className="ttm-list-li-content">
                        We guide our clients through the visa application
                        process, ensuring they have all the necessary documents
                        and information required for their application.
                      </div>
                    </li>
                  </ul>
                </div>
                <a
                  className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                  href={process.env.PUBLIC_URL + "/about_us"}
                >
                  view more
                </a>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-10 col-12 mx-auto">
                <div className="mr-40 ml-20 pb-60 pt-lg-50">
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="pt-20 pr-20 bg-theme-WhiteColor ml_20 mb_60 mt-200">
                      <Img
                        src="images/images/vandari.jpg"
                        className="img-fluid"
                        alt="bgimage"
                      />
                    </div>
                    <div className="d-flex align-items-start h-100 mr_30 pt-50">
                      <div className="ttm-play-icon-btn p-20 bg-theme-SkinColor">
                        <Video className="flaticon-play-button text-theme-WhiteColor" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* about-section end */}

        {/* features-section */}
        <section className="ttm-row features-section bg-theme-GreyColor clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      What{" "}
                      <span className="text-theme-SkinColor">we serve!</span>
                    </h3>
                    <h2 className="title">At Workwell Solutions BD</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* row */}
            <div className="row row-equal-height mb_10">
              <div className="col-lg-4 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-before-title style3">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon-research"></i>
                    </div>
                  </div>
                  <div className="featured-title">
                    <h3>Work Permit and Visa Help</h3>
                  </div>
                  <div className="featured-content">
                    <div className="featured-desc">
                      <p>
                        Our experienced team guides clients through the process
                        of obtaining work permits and visas for various European
                        countries. We ensure that all documentation and
                        application processes are completed accurately and
                        efficiently.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-before-title style3">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon-recruitment-7"></i>
                    </div>
                  </div>
                  <div className="featured-title">
                    <h3>Job Placement</h3>
                  </div>
                  <div className="featured-content">
                    <div className="featured-desc">
                      <p>
                        We connect job seekers with reputable employers in
                        Europe, matching their skills and expertise with the
                        available job openings in diverse industries such as
                        construction, agriculture, hospitality, and more.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-before-title style3">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon-recruiting"></i>
                    </div>
                  </div>
                  <div className="featured-title">
                    <h3>Skill Development and Training</h3>
                  </div>
                  <div className="featured-content">
                    <div className="featured-desc">
                      <p>
                        To enhance our clients' employability, we provide skill
                        development and training programs tailored to the
                        requirements of the European job market. This includes
                        language courses, technical training, and vocational
                        programs.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-before-title style3">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon-recruitment-7"></i>
                    </div>
                  </div>
                  <div className="featured-title">
                    <h3>Pre-Departure Support</h3>
                  </div>
                  <div className="featured-content">
                    <div className="featured-desc">
                      <p>
                        We provide comprehensive pre-departure support to help
                        our clients prepare for their journey to Europe. This
                        includes assistance with travel arrangements,
                        accommodation, and orientation sessions to familiarize
                        clients with their destination country's culture, laws,
                        and regulations.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-before-title style3">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon-recruitment"></i>
                    </div>
                  </div>
                  <div className="featured-title">
                    <h3>Post-Arrival Support</h3>
                  </div>
                  <div className="featured-content">
                    <div className="featured-desc">
                      <p>
                        Our services extend beyond securing job placements. We
                        continue to support our clients after their arrival in
                        Europe by helping them integrate into their new work
                        environment and community. This includes assistance with
                        settling in, understanding workplace expectations, and
                        addressing any challenges they may face.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-before-title style3">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon-technical-support"></i>
                    </div>
                  </div>
                  <div className="featured-title">
                    <h3>Ongoing Career Support</h3>
                  </div>
                  <div className="featured-content">
                    <div className="featured-desc">
                      <p>
                        We are committed to our clients' long-term success,
                        offering ongoing career support and guidance to help
                        them grow professionally and achieve their goals in
                        Europe.
                      </p>
                    </div>
                  </div>
                </div>
                {/* ttm-box-view-overlay end */}
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* features-section end */}

        {/* padding_zero-section */}
        <section className="ttm-row padding_zero-section bg-theme-DarkColor bg-layer-equal-height mb-100 mb-lg-0 clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-6">
                {/* col-img-img-four */}
                <div
                  className="col-bg-img-four ttm-col-bgimage-yes ttm-bg mt-100 mt-lg-60 mr-30 mr-lg-0 border border-15
                            border-white p-15 h-100"
                >
                  <ClientsSlider></ClientsSlider>
                  <div className="layer-content"></div>
                  <Img
                    src="images/slides/05.jpg"
                    className="img-fluid col-bg-img-res"
                    alt="bgimage"
                  />
                </div>
                {/* col-img-bg-img-four end */}
              </div>
              <div className="col-lg-6">
                <div className="pt-140 pt-lg-50 pb-100 pb-lg-60">
                  {/* section title */}
                  <div className="section-title">
                    <div className="title-header">
                      <h3>
                        Happy{" "}
                        <span className="text-theme-SkinColor">Clients</span>
                      </h3>
                      <h2 className="title">Listen Our Clients Stories</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="position-relative">
                    <Slider
                      className="slick_slider slick-arrows-style3"
                      {...slick_slider}
                      slidesToShow={1}
                      arrows={true}
                      fade={true}
                      {...settings}
                    >
                      {sliderElements.map((element) => (
                        <div key={element.id}>
                          <p>{element.description}</p>
                        </div>
                      ))}
                    </Slider>
                    <div className="slick_slider_countable">
                      <span className="currentSlide">
                        {this.state.currentSlide + 1}
                      </span>
                      <span> / </span>
                      <span className="totalSlide">
                        {sliderElements.length}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* padding_zero-section end */}

        {/* services-section */}
        <section
          className="ttm-row services-section bg-img1 bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix"
          style={{
            backgroundImage: `url(${euImage})`,
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-11">
                {/* section title */}
                <div className="section-title style2 mb-0">
                  <div className="title-header">
                    <h3>
                      We Serve{" "}
                      <span className="text-theme-SkinColor">Overseas</span>
                    </h3>
                    <h2 className="title">EU Job Opportunities</h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      At Workwell Solutions BD,{" "}
                      <span className="text-theme-SkinColor">
                        we specialize{" "}
                      </span>
                      in providing comprehensive job assistance services for
                      those seeking opportunities in the European Union.
                    </p>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* Slider */}
            <Slider
              className="row slick_slider slick-arrows-style1"
              {...slick_slider}
              slidesToShow={3}
              arrows={true}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: { slidesToShow: 2, slidesToScroll: 2 },
                },
                {
                  breakpoint: 650,
                  settings: { slidesToShow: 1, slidesToScroll: 1 },
                },
              ]}
            >
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-country">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell solutions"
                      src={"images/country/Flag_of_the_Czech_Republic.svg.png"}
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a
                          rel="noreferrer"
                          target={"_blank"}
                          href="https://en.wikipedia.org/wiki/Czech_Republic"
                        >
                          Czech Republic
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        A Central European country famous for historic castles,
                        architecture, and delicious beer.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-country">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell solutions"
                      src="images/country/Civil_ensign_of_Croatia.svg.png"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a
                          rel="noreferrer"
                          target={"_blank"}
                          href="https://en.wikipedia.org/wiki/Croatia"
                        >
                          Croatia
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Known for its breathtaking coastline along the Adriatic
                        Sea and beautiful historic cities.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-country">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell solutions"
                      src="images/country/Flag_of_Romania.svg.png"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a
                          rel="noreferrer"
                          target={"_blank"}
                          href="https://en.wikipedia.org/wiki/Romania"
                        >
                          Romania
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        A country famous for its rugged Carpathian Mountains,
                        medieval castles, and rich folklore.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-country">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell solutions"
                      src="images/country/Flag_of_Malta.svg.png"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a
                          rel="noreferrer"
                          target={"_blank"}
                          href="https://en.wikipedia.org/wiki/Malta"
                        >
                          Malta
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        A Mediterranean archipelago with a rich history,
                        stunning beaches, and unique architecture.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-country">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell solutions"
                      src="images/country/Civil_Ensign_of_Hungary.svg.png"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/"}>Hungary</a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        A landlocked country known for its thermal baths,
                        historic architecture, folk traditions.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-country">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell solutions"
                      src="images/country/Flag_of_Poland.png"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/"}>Poland</a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        A country with a tumultuous history,it's beautiful
                        cities, and a rich cultural heritage.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-country">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell solutions"
                      src="images/country/Flag_of_Lithuania.png"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/"}>Lithuania</a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Another Baltic gem, known for its historic castles, it's
                        beautiful countryside, and vibrant culture.
                      </p>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
            </Slider>
            {/* Slider end */}
          </div>
        </section>
        {/* services-section end */}

        {/* client-section */}
        <section className="ttm-row client-section bg-theme-GreyColor clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                {/* section-title */}
                <div className="section-title">
                  <div className="title-header">
                    <h3>
                      our <span className="text-theme-SkinColor">clients</span>
                    </h3>
                    <h2 className="title">Partnership With Top Companies</h2>
                  </div>
                </div>
                {/* section-title end */}
                <a
                  className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                  href={process.env.PUBLIC_URL + "/"}
                >
                  Explore More Companies
                </a>
              </div>
              <div className="col-lg-8">
                <div className="row g-0 ttm-vertical_sep mt-lg-50">
                  <div className="col-sm-4">
                    <div className="client-box">
                      <div className="client-thumbnail">
                        <Img
                          draggable="false"
                          className="img-fluid"
                          alt="Workwell solutions"
                          src="images/partner/Playtech_logo.svg.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="client-box">
                      <div className="client-thumbnail">
                        <Img
                          className="img-fluid"
                          alt="Workwell solutions"
                          src="images/partner/b_RTU_skontobuve_logo.jpg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="client-box">
                      <div className="client-thumbnail">
                        <Img
                          className="img-fluid"
                          alt="Workwell solutions"
                          src="images/partner/loreoallogo.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-horizontal_sep width-100"></div>
                <div className="row g-0 ttm-vertical_sep">
                  <div className="col-sm-4">
                    <div className="client-box">
                      <div className="client-thumbnail">
                        <Img
                          className="img-fluid"
                          alt="Workwell solutions"
                          src="images/partner/romstal.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="client-box">
                      <div className="client-thumbnail">
                        <Img
                          className="img-fluid"
                          alt="Workwell solutions"
                          src="images/partner/reslogo.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="client-box mb-0">
                      <div className="client-thumbnail">
                        <Img
                          className="img-fluid"
                          alt="Workwell solutions"
                          src="images/partner/Strabag.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* client-section end */}

        {/* fid-section */}
        <section
          className="ttm-row fid-section bg-img4 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix img-fluid"
          style={{
            backgroundImage: 'url("images/services/transport-hover.png")',
          }}
        >
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
          <div className="container">
            {/* row */}
            <div className="row ttm-vertical_sep mt_lg-15">
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* fid */}
                <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                  <div className="ttm-fid-icon-wrapper">
                    <i className="flaticon flaticon-headhunting"></i>
                  </div>
                  <div className="ttm-fid-contents">
                    <h4>
                      <CountUp start={0} end={2705} duration={20} delay={2} />
                    </h4>
                    <h3 className="ttm-fid-title">Job seeker</h3>
                  </div>
                </div>
                {/* fid end */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* fid */}
                <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                  <div className="ttm-fid-icon-wrapper">
                    <i className="flaticon flaticon-technical-support"></i>
                  </div>
                  <div className="ttm-fid-contents">
                    <h4>
                      <CountUp start={0} end={180} duration={20} delay={2} />
                    </h4>
                    <h3 className="ttm-fid-title">Areas of expertise</h3>
                  </div>
                </div>
                {/* fid end */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* fid */}
                <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                  <div className="ttm-fid-icon-wrapper">
                    <i className="flaticon flaticon-recruitment-4"></i>
                  </div>
                  <div className="ttm-fid-contents">
                    <h4>
                      <CountUp start={0} end={3260} duration={20} delay={2} />
                    </h4>
                    <h3 className="ttm-fid-title">career hub</h3>
                  </div>
                </div>
                {/* fid end */}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* fid */}
                <div className="ttm-fid inside ttm-fid-view-lefticon style3">
                  <div className="ttm-fid-icon-wrapper">
                    <i className="flaticon flaticon-recruitment-3"></i>
                  </div>
                  <div className="ttm-fid-contents">
                    <h4>
                      <CountUp start={0} end={9784} duration={20} delay={2} />
                    </h4>
                    <h3 className="ttm-fid-title">Larger Community</h3>
                  </div>
                </div>
                {/* fid end */}
              </div>
            </div>
            {/* row end */}
            <div className="ttm-horizontal_sep width-100 pt-60 mt-60 mt-lg-40"></div>
            <div className="row align-items-center mb_35 mb-md-0">
              <div className="col-lg-8 col-md-8">
                {/* section title */}
                <div className="section-title">
                  <div className="title-header">
                    <h3>
                      Over{" "}
                      <CountUp start={1} end={530} duration={20} delay={2} />
                      {" + "}
                      Satisfied{" "}
                      <span className="text-theme-SkinColor">
                        {" "}
                        Clients and Counting
                      </span>
                    </h3>
                    <h2 className="title">
                      <span className="fw-normal">Are you ready to</span> become
                      <span className="fw-normal">
                        {" "}
                        one of our success stories?
                      </span>
                    </h2>
                    <p>
                      {" "}
                      Let’s To Work Together Ready To Work
                      <span className="fw-normal"> With Us.</span>
                    </p>
                  </div>
                </div>
                {/* section title end */}
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="d-flex align-items-center justify-content-md-end">
                  <h6 className="font-weight-bold mb-0 mr-15">Watch Video!</h6>
                  <a className=" d-flex">
                    <span className="mb-0 p-10 ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-rounded margin_right10 margin_bottom0">
                      <Video className="fa fa-play" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* fid-section end */}

        {/* about-section */}
        <section className="ttm-row about-section clearfix">
          <div className="container">
            {/* row */}
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                {/* section title */}
                <div className="section-title">
                  <div className="title-header">
                    <h3>
                      how <span className="text-theme-SkinColor"> we do!</span>
                    </h3>
                    <h2 className="title">Workwell Solutions process</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
              <div className="col-lg-6 col-md-4">
                <a
                  className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark mb-15"
                  href={process.env.PUBLIC_URL + "/contact_us"}
                >
                  Get Started
                </a>
              </div>
              <div className="col-lg-6">
                <div className="featuredbox-number pr-30 pr-lg-0 pb-lg-50 pt-md-20">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                        <i className="ttm-num ti-info"></i>
                      </div>
                    </div>
                    <div className="featured-content ttm-bgcolor-grey">
                      <div className="featured-title">
                        <h3>Initial Consultation</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We start by understanding your skills, qualifications,
                          and career goals through a one-on-one consultation.
                          This allows us to identify the most suitable job
                          opportunities that align with your aspirations.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                        <i className="ttm-num ti-info"></i>
                      </div>
                    </div>
                    <div className="featured-content ttm-bgcolor-grey">
                      <div className="featured-title">
                        <h3> Job Search</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Our team of experts conducts an extensive search to
                          identify job openings in our partner companies across
                          various European countries, focusing on the categories
                          you are interested in.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                        <i className="ttm-num ti-info"></i>
                      </div>
                    </div>
                    <div className="featured-content ttm-bgcolor-grey">
                      <div className="featured-title">
                        <h3>Application Preparation</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We assist you in preparing your job application,
                          including creating a professional CV, drafting a
                          compelling cover letter, and gathering the necessary
                          supporting documents.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                        <i className="ttm-num ti-info"></i>
                      </div>
                    </div>
                    <div className="featured-content ttm-bgcolor-grey">
                      <div className="featured-title">
                        <h3>Interview Coaching</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          To increase your chances of success, we provide
                          personalized interview coaching, including tips on how
                          to effectively answer questions and showcase your
                          skills and experience.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                </div>
              </div>
              <div className="col-lg-6 col-md-10 col-11 m-auto">
                <div className="featuredbox-number pr-30 pr-lg-0 pb-lg-50 pt-md-20">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                        <i className="ttm-num ti-info"></i>
                      </div>
                    </div>
                    <div className="featured-content ttm-bgcolor-grey">
                      <div className="featured-title">
                        <h3>Job Offer and Contract</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Once you receive a job offer from a potential
                          employer, we help you review the contract and
                          negotiate the terms, ensuring that it meets your
                          expectations and requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                        <i className="ttm-num ti-info"></i>
                      </div>
                    </div>
                    <div className="featured-content ttm-bgcolor-grey">
                      <div className="featured-title">
                        <h3>Visa and Work Permit Assistance</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Our team provides comprehensive support in obtaining
                          your work visa and work permit for the European
                          country you will be working in, guiding you through
                          the application process and addressing any concerns.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                        <i className="ttm-num ti-info"></i>
                      </div>
                    </div>
                    <div className="featured-content ttm-bgcolor-grey">
                      <div className="featured-title">
                        <h3>Pre-departure Briefing</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Before you leave for your new job, we provide a
                          pre-departure briefing to ensure you are well-prepared
                          for your journey, including information about the
                          country, culture, and workplace expectations.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                        <i className="ttm-num ti-info"></i>
                      </div>
                    </div>
                    <div className="featured-content ttm-bgcolor-grey">
                      <div className="featured-title">
                        <h3>Ongoing Support</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We remain available to assist you with any issues or
                          concerns that may arise during your employment in
                          Europe, ensuring a smooth and successful transition.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* about-section end */}

        {/* blog-section */}
        <section className="ttm-row blog-section clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      Extend{" "}
                      <span className="text-theme-SkinColor">Knowledge</span>
                    </h3>
                    <h2 className="title">Latest News & Articles</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* Slider */}
            <Slider
              className="row slick_slider mb_10"
              {...slick_slider}
              slidesToShow={3}
              arrows={false}
              autoplay={false}
            >
              <div className="col-md-12">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/images/req01.jpg"
                    />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          26
                          <span className="entry-month entry-year">Mar</span>
                        </time>
                      </span>
                    </div>
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        Workwell Solutions Bd
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          Top Industries with High Demand for Skilled Workers in
                          Europe
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Discover the sectors that are currently experiencing a
                        shortage of skilled workers and learn about the job
                        opportunities available in these industries.{" "}
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/Services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/images/req02.jpg"
                    />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          22
                          <span className="entry-month entry-year">Dec</span>
                        </time>
                      </span>
                    </div>
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        Workwell Solutions Bd
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          How to Stand Out in the European Job Market
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Get valuable tips and advice on how to create a
                        compelling CV and cover letter that can make you stand
                        out from the competition in the European job market.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/Services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/images/req03.jpg"
                    />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          04
                          <span className="entry-month entry-year">Mar</span>
                        </time>
                      </span>
                    </div>
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        Workwell Solutions Bd
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          Navigating the Work Permit and Visa Process
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Learn about the different types of work permits and
                        visas available in Europe and understand the steps
                        involved in the application process.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/Services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/images/req04.jpg"
                    />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          21
                          <span className="entry-month entry-year">Jan</span>
                        </time>
                      </span>
                    </div>
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        Workwell Solutions Bd
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          Cultural Differences and Workplace Etiquette in Europe
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Familiarize yourself with the customs and traditions in
                        various European countries and gain insights into
                        workplace etiquette and expectations.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/Services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/images/req05.jpg"
                    />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          11
                          <span className="entry-month entry-year">April</span>
                        </time>
                      </span>
                    </div>
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        Workwell Solutions Bd
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          The Benefits of Working in Europe
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Explore the advantages of working in Europe, including
                        competitive salaries, social benefits, and opportunities
                        for personal and professional growth.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/Services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/images/req06.jpg"
                    />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          03
                          <span className="entry-month entry-year">Feb</span>
                        </time>
                      </span>
                    </div>
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        Workwell Solutions Bd
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          Tips for a Successful Job Interview in Europe
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Prepare for job interviews in Europe with expert advice
                        on how to showcase your skills, experience, and cultural
                        adaptability.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/Services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
            </Slider>
            {/* Slider end */}
          </div>
        </section>
        {/* blog-section end */}
        <CookieConsent enableDeclineButton flipButtons>
          This website uses cookies to enhance the user experience. <br></br>{" "}
          <br></br>
          <a
            rel="noreferrer"
            className="developerinfo"
            target="_blank"
            href="https://www.linkedin.com/in/sajeeb-ahmed/"
            style={{
              backgroundColor: "rgb(5 33 39)",
              color: "white",
              textDecoration: "none",
              padding: "8px 16px",
              borderRadius: "4px",
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            Contact With Developer
          </a>
        </CookieConsent>
        <Footer />
      </div>
    );
  }
}

export default Home;
