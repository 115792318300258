import React from "react";

function Img({ src, alt, className }) {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/${src}`}
      alt={alt}
      className={className}
    />
  );
}

export default Img;
