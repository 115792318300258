import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBHamburgerToggler,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
import { Link } from "react-router-dom";

class Mobile_menu extends Component {
  state = {
    collapse1: false,
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  toggleSingleCollapse = (collapseId) => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  };

  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler
            id="hamburger1"
            onClick={() => this.toggleSingleCollapse("collapse1")}
          />
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            <MDBNavbarNav left>
              {
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/"}
                      style={{ color: "#0c0c0c" }}
                    >
                      Home
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/services"}
                      style={{ color: "#0c0c0c" }}
                    >
                      Services
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      style={{ color: "#0c0c0c" }}
                      href={process.env.PUBLIC_URL + "/Europe-Work-Visa"}
                    >
                      Europe Work Visa
                    </MDBDropdownItem>

                    <MDBDropdownItem
                      style={{ color: "#0c0c0c" }}
                      href={process.env.PUBLIC_URL + "/Job_list"}
                    >
                      Jobs
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      style={{ color: "#0c0c0c" }}
                      href={process.env.PUBLIC_URL + "/about_us"}
                    >
                      About Us
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/Login"}
                      style={{ color: "#0c0c0c" }}
                    >
                      Login
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      style={{ color: "#0c0c0c" }}
                      href={process.env.PUBLIC_URL + "/Contact_us"}
                    >
                      Contact
                    </MDBDropdownItem>
                  </MDBDropdown>
                </MDBNavItem>
              }
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobile_menu;
