import React, { Component } from "react";
import Img from "../../pages/Img";

export class Footer extends Component {
  render() {
    return (
      <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix">
        <div className="second-footer">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area">
                <div className="widget widget_text clearfix">
                  <div className="footer-logo">
                    <Img
                      id="footer-logo-img"
                      className="img-fluid auto_size"
                      height="60"
                      width="182"
                      src="images/footer-logo.png"
                      alt="footer-logo"
                    />
                  </div>
                  <p>
                    Workwell Solutions BD, we take pride in our track record of
                    placing more than 50,000 satisfied clients in rewarding
                    employment opportunities across Europe. Our commitment to
                    excellence, customer satisfaction, and personalized service
                    has made us a preferred partner for job seekers looking to
                    work in Europe.
                  </p>
                  <div className="d-flex align-items-center pt-15 pb-25">
                    <h6 className="fw-normal">Social Share: </h6>
                    <div className="social-icons">
                      <ul className="social-icons list-inline">
                        <li>
                          <a
                            href="https://www.facebook.com/workwellbd"
                            rel="noopener"
                            aria-label="facebook"
                          >
                            <i className="ti ti-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/workwellbd"
                            rel="noopener"
                            aria-label="twitter"
                          >
                            <i className="ti ti-twitter-alt"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/workwellbd"
                            rel="noopener"
                            aria-label="instagram"
                          >
                            <i className="ti ti-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/workwellbd"
                            rel="noopener"
                            aria-label="pinterest"
                          >
                            <i className="ti ti-pinterest"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                    href={process.env.PUBLIC_URL + "/Contact_us"}
                  >
                    Request A Free Quotes
                  </a>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                <div className="widget widget-recent-post clearfix">
                  <h3 className="widget-title">
                    {" "}
                    Connect with Workwell Solutions BD - Your European
                    Employment Partner
                  </h3>
                  <ul className="widget-post ttm-recent-post-list">
                    <li>
                      <div className="post-detail">
                        <div className="cat-link">
                          <a
                            href={process.env.PUBLIC_URL + "/Europe-Work-Visa"}
                          >
                            Services and Work Visas
                          </a>
                        </div>
                        <a href={process.env.PUBLIC_URL + "/Europe-Work-Visa"}>
                          Explore our work visa offerings and diverse job
                          opportunities across various sectors for skilled
                          professionals.
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="post-detail">
                        <div className="cat-link">
                          <a
                            href={process.env.PUBLIC_URL + "/Services_details"}
                          >
                            Success Stories
                          </a>
                        </div>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          Be inspired by our clients' accomplishments in
                          building thriving careers in Europe with our
                          assistance.
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="post-detail">
                        <div className="cat-link">
                          <a href={process.env.PUBLIC_URL + "/Contact_us"}>
                            Contact
                          </a>
                        </div>
                        <a href={process.env.PUBLIC_URL + "/Contact_us"}>
                          Reach out to our expert team to start your European
                          employment journey. We're here to guide you every step
                          of the way.
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                <div className="widget widget-contact clearfix">
                  <h3 className="widget-title">Quick Contact!</h3>
                  <ul className="widget_contact_wrapper">
                    <li>
                      <i className="ttm-textcolor-skincolor flaticon-phone-call"></i>
                      <h3>Call Us On:</h3>
                      <a href="tel:+8801988734762">+8801988734762</a>
                    </li>
                    <li>
                      <i className="ttm-textcolor-skincolor flaticon-placeholder"></i>
                      <h3>Address:</h3>
                      <a
                        href="https://www.google.com/maps/search/?api=1&query=23.7609166,90.3612863"
                        target="_blank"
                      >
                        #05 Road #12 House #7th Level, Bosila Model Town,
                        Mohammadpur, Dhaka, Bangladesh
                      </a>
                    </li>
                    <li>
                      <i className="ttm-textcolor-skincolor flaticon-email"></i>
                      <h3>Email:</h3>
                      <a href="mailto:info@workwellsolutionsbd.com">
                        info@workwellsolutionsbd.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer-text">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-6">
                <span className="copyright">
                  Copyright © {new Date().getFullYear()}.
                  <a href={process.env.PUBLIC_URL + "/"}>
                    {" "}
                    Workwell Solutions BD .
                  </a>
                  All rights reserved.
                </span>
                <ul className="footer-nav-menu">
                  <li>
                    <a href={process.env.PUBLIC_URL + "/"}>Privacy & Policy</a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/"}>
                      Terms & Conditions
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6 d-lg-flex align-items-center justify-content-between">
                <form
                  id="subscribe-form"
                  className="subscribe-form"
                  action="mailto:info@workwellsolutionsbd.com"
                  method="post"
                  enctype="text/plain"
                >
                  <div
                    className="mailchimp-inputbox clearfix"
                    id="subscribe-content"
                  >
                    <input
                      type="email"
                      name="EMAIL"
                      id="txtemail"
                      placeholder="Enter Your Email Address..."
                      required=""
                    />
                    <button
                      className="submit ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-skincolor"
                      type="submit"
                    >
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
