import React, { Component } from "react";
import Img from "../../pages/Img";

class Logo extends Component {
  render() {
    return (
      <a
        className="home-link"
        href={process.env.PUBLIC_URL + "/"}
        title="Workwell Solutions Bd"
        rel="home"
      >
        <Img
          id="logo-img"
          height="42"
          width="170"
          className="img-fluid auto_size"
          src="images/logo-img.png"
          alt="logo-img"
        />
      </a>
    );
  }
}

export default Logo;
