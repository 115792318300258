import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";

export class Menu extends Component {
  state = {
    show: false,
  };
  render() {
    return (
      <Router>
        <nav className="main-menu">
          <ul className="menu">
            <li className="mega-menu-item">
              <a href={process.env.PUBLIC_URL + "/"} className="mega-menu-link">
                Home
              </a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/about_us"}>About Us</a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/services"}>Services</a>
            </li>

            <li>
              <a href={process.env.PUBLIC_URL + "/contact_us"}>Contact us</a>
            </li>
            <li>
              <a href={process.env.PUBLIC_URL + "/Europe-Work-Visa"}>
                Europe Work Visa
              </a>
            </li>

            <li className="mega-menu-item">
              <a
                href={process.env.PUBLIC_URL + "/about_us"}
                className="mega-menu-link"
              >
                About us
              </a>
            </li>
            <li className="mega-menu-item">
              <a
                href={process.env.PUBLIC_URL + "/job_list"}
                className="mega-menu-link"
              >
                Jobs
              </a>
            </li>
          </ul>
        </nav>
      </Router>
    );
  }
}

export default Menu;
