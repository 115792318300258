import React, { Component } from "react";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
import { Footer } from "../components/layout/Footer";
import Img from "./Img";
import { Helmet } from "react-helmet";

export class Services_details extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />

        {/* PageHeader */}
        <PageHeader title="services details" breadcrumb="services" />
        {/* PageHeader end */}
        <Helmet>
          <title>
            {" "}
            Services Details - WorkWell Solutions BD -Europe Work Visa Guide{" "}
          </title>
          <meta
            name="description"
            content="Explore opportunities for Europe Work Visa with WorkWell Solutions BD. Learn about Romania and Poland Work Visa, Work Permit in Europe, Malta and Croatia Job Visa and more."
          />
          <meta
            name="keywords"
            content="workwell, workwell solutions, workwell solutions bd, workwellsolutionsbd, vandary, vandary faruk, europe work visa, romania work visa, poland work visa, work permit europe, malta job visa, croatia work visa, job in europe, bangladesh europe job, estonia job, workvisaeurope, jobsineurope, romania job visa, restaurant job, agriculture job visa europe, italy job visa, italy agriculture visa, work well solution bd, workwellsolution, workwellsolutionsbd, skyasiabd, skyasia, sky asia, job visa europe"
          />
        </Helmet>
        <div className="ttm-row sidebar ttm-sidebar clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-8 content-area order-lg-2">
                {/* ttm-service-single-content-area */}
                <div className="ttm-service-single-content-area">
                  <div className="ttm_single_image-wrapper">
                    <Img
                      className="img-fluid"
                      src="images/slides/vandari1.jpg"
                      alt="Europe job visa - workwell soluations bd "
                    />
                  </div>
                  <div className="ttm-service-description mt-40">
                    <h3 className="mb-20">Description For Our Service</h3>
                    <p>
                      At Workwell Solutions BD, we specialize in identifying and
                      placing top-tier talent in roles that align with their
                      skills and aspirations. Our proven methodologies ensure
                      the right fit for both the candidate and the company.
                    </p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                              <i className="flaticon-research"></i>
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3> Workforce Optimization</h3>
                            </div>
                            <div className="featured-desc">
                              <p>
                                Boosting productivity and efficiency through
                                data-driven strategies tailored for modern
                                businesses.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* featured-icon-box end */}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                              <i className="flaticon-healthcare"></i>
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3>Strategic Employment Solutions</h3>
                            </div>
                            <div className="featured-desc">
                              <p>
                                Offering flexible, bespoke hiring solutions
                                including contract staffing and executive
                                search.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* featured-icon-box end */}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                              <i className="flaticon-conveyor"></i>
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3>Skill Development & Training</h3>
                            </div>
                            <div className="featured-desc">
                              <p>
                                Bridging skill gaps with tailored training
                                programs, from language courses to technical
                                upskilling.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* featured-icon-box end */}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                          <div className="featured-icon">
                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                              <i className="flaticon-human-resources-3"></i>
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3>Visa & Work Permit Assistance</h3>
                            </div>
                            <div className="featured-desc">
                              <p>
                                Simplifying the complex visa application process
                                for European countries, ensuring timely and
                                successful outcomes.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* featured-icon-box end */}
                      </div>
                    </div>
                    <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                    <div className="row">
                      <div className="col-md-6">
                        <h4>The Best Aquarist You Can Make is In Your Self</h4>
                        <p>
                          Here’s why the best investment in aquarium keeping is
                          in yourself:
                        </p>
                        <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                          <li>
                            <div className="ttm-list-li-content">
                              Knowledge is Power: The more you know about the
                              needs of your aquatic pets, the better you can
                              provide for them.
                            </div>
                          </li>

                          <li>
                            <div className="ttm-list-li-content">
                              Building Intuition: As you grow in your journey,
                              you'll develop an intuition for your tank’s needs.
                            </div>
                          </li>
                          <li>
                            <div className="ttm-list-li-content">
                              Personal Satisfaction: There’s an unparalleled
                              satisfaction in knowing that you’ve nurtured an
                              environment where aquatic life can thrive.
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <div className="ttm_single_image-wrapper mb-30">
                          <Img
                            className="img-fluid"
                            src="images/images/workwell.jpg"
                            alt="services-img"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <p>
                          In essence, while tools and technologies can aid in
                          the process, the heart of aquarium keeping is the
                          aquarist themselves. Dedicate yourself to the craft,
                          invest time in learning and observation, and you'll
                          discover that the most invaluable asset in aquarium
                          keeping is, indeed, yourself.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-4 widget-area">
                <div className="sidebar-right">
                  <aside className="widget widget-nav-menu">
                    <ul>
                      <li>
                        <a
                          className="active"
                          href={process.env.PUBLIC_URL + "/services_details"}
                        >
                          Manpower Supply
                        </a>
                      </li>
                      <li>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Technical Services
                        </a>
                      </li>
                      <li>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Visa & Work Permit Assistance
                        </a>
                      </li>
                      <li>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Workforce Optimization
                        </a>
                      </li>
                      <li>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Skill Development & Training
                        </a>
                      </li>
                      <li>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Talent Acquisition
                        </a>
                      </li>
                    </ul>
                  </aside>
                  <aside className="widget widget-download">
                    <ul className="download">
                      <li>
                        <span className="download-img">
                          <Img src="images/pdf-img.png" alt="pdf-img" />
                        </span>
                        <span className="download-caption">
                          <span>Case Studies (EU) </span>
                          <h6>Audit & Assuarance</h6>
                          <a
                            className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-left ttm-btn-color-grey"
                            href="/"
                          >
                            <i className="ti ti-arrow-right"></i>Download (3.5
                            MB)
                          </a>
                        </span>
                      </li>
                    </ul>
                  </aside>
                  <aside className="widget widget-banner">
                    <div className="ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg p-30 pb-50 pt-45">
                      <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                        <div className="ttm-col-wrapper-bg-layer-inner"></div>
                      </div>
                      <div className="layer-content text-center text-theme-WhiteColor">
                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-style-round ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                          <i className="far fa-comments"></i>
                        </div>
                        <h3>Do You Need Any Help?</h3>
                        <div className="ttm-horizontal_sep width-100 mt-25 mb-25"></div>
                        <ul>
                          <li>
                            {" "}
                            <a href="tel:+8801988734762"> +8801988734762</a>
                          </li>
                          <li>
                            <a href="mailto:info@workwellsolutionsbd.com">
                              info@workwellsolutionsbd.com
                            </a>
                          </li>
                        </ul>
                        <a
                          className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                                ttm-btn-color-skincolor"
                          href={process.env.PUBLIC_URL + "/contact_us"}
                        >
                          appoinments!
                        </a>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Services_details;
