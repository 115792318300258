import React, { Component } from "react";
import Slider from "react-slick";
import ProgressBar from "react-animated-progress-bar";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
import { Footer } from "../components/layout/Footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CountUp from "react-countup";
import Video from "../components/layout/Video";
import Img from "./Img";
import workweeljobvisacover from "../images/images/eu-map.jpg";
import workweeljobvisacovereu from "../images/images/euf.jpg";
import { Helmet } from "react-helmet";
export class About_us extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      rows: 1,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 778,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="site-main">
        <Header />

        {/* PageHeader */}
        <PageHeader title="about us" breadcrumb="about" />
        {/* PageHeader end */}
        <Helmet>
          <title> About WorkWell Solutions BD -Europe Work Visa Guide </title>
          <meta
            name="description"
            content="Explore opportunities for Europe Work Visa with WorkWell Solutions BD. Learn about Romania and Poland Work Visa, Work Permit in Europe, Malta and Croatia Job Visa and more."
          />
          <meta
            name="keywords"
            content="workwell, workwell solutions, workwell solutions bd, workwellsolutionsbd, vandary, vandary faruk, europe work visa, romania work visa, poland work visa, work permit europe, malta job visa, croatia work visa, job in europe, bangladesh europe job, estonia job, workvisaeurope, jobsineurope, romania job visa, restaurant job, agriculture job visa europe, italy job visa, italy agriculture visa, work well solution bd, workwellsolution, workwellsolutionsbd, skyasiabd, skyasia, sky asia, job visa europe"
          />
        </Helmet>
        {/* about-section */}
        <section className="ttm-row about-section clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title">
                  <div className="title-header">
                    <h3>
                      Great{" "}
                      <span className="text-theme-SkinColor">Search! </span>
                    </h3>
                    <h2 className="title">Workwell Solutions BD</h2>
                  </div>
                  <div className="title-desc">
                    <p>– Where Dreams Meet Opportunities.</p>
                  </div>
                </div>
                {/* section title end */}
                <div className="ttm-tabs ttm-tab-style-01">
                  <Tabs>
                    <TabList className="tabs">
                      <Tab className="tab">
                        <a className="tab-1" tabIndex="0">
                          Our Mision
                        </a>
                      </Tab>
                      <Tab className="tab">
                        <a className="tab-2" tabIndex="0">
                          Our Vision
                        </a>
                      </Tab>
                      <Tab className="tab">
                        <a className="tab-3" tabIndex="0">
                          Core Values
                        </a>
                      </Tab>
                    </TabList>
                    <div className="content-tab">
                      <TabPanel>
                        <div className="row">
                          <div className="col-lg-4 col-md-3 col-sm-4">
                            <Img
                              className="img-fluid  "
                              height="200"
                              width="200"
                              src="images/images/eus3.jpg"
                              alt="workwell soluations bd "
                            />
                          </div>
                          <div className="col-lg-8 col-md-9 col-sm-8">
                            <div className="pt-15">
                              <p>
                                At Workwell Solutions BD, our mission is clear
                                and unwavering: to empower individuals with
                                genuine opportunities for European employment,
                                bridging the gap between talent and demand. We
                                are committed to uplifting lives, transforming
                                aspirations into reality, and fostering economic
                                growth both for individuals and our nation
                                through genuine work visas and unparalleled
                                service.
                              </p>
                              <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li>
                                  <i className="far fa-check-circle"></i>
                                  <div className="ttm-list-li-content">
                                    An ability to attract and retain great
                                    talent
                                  </div>
                                </li>
                                <li>
                                  <i className="far fa-check-circle"></i>
                                  <div className="ttm-list-li-content">
                                    A talent need, quickly and cost-effectively
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="row">
                          <div className="col-lg-4 col-md-3 col-sm-4">
                            <Img
                              className="img-fluid "
                              height="200"
                              width="200"
                              src="images/slides/euflag.jpg"
                              alt="workwell soluations bd europe work visa  "
                            />
                          </div>
                          <div className="col-lg-8 col-md-9 col-sm-8">
                            <div className="pt-15">
                              <p>
                                Our horizon is vast and our goals ambitious. We
                                envision a future where:
                              </p>
                              <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li>
                                  <i className="far fa-check-circle"></i>
                                  <div className="ttm-list-li-content">
                                    Every qualified individual finds their dream
                                    job, regardless of geographical boundaries.
                                  </div>
                                </li>
                                <li>
                                  <i className="far fa-check-circle"></i>
                                  <div className="ttm-list-li-content">
                                    Bangladesh stands as a beacon of talent,
                                    with its citizens making remarkable
                                    contributions to European economies and
                                    societies.
                                  </div>
                                </li>
                                <li>
                                  <i className="far fa-check-circle"></i>
                                  <div className="ttm-list-li-content">
                                    The barriers of language, skillset, and
                                    bureaucracy are seamlessly navigated, making
                                    international employment accessible and
                                    hassle-free.
                                  </div>
                                </li>
                              </ul>
                              <p>
                                We strive to be recognized globally as the most
                                trusted, efficient, and compassionate bridge
                                between job seekers and European employers
                              </p>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="row">
                          <div className="col-lg-4 col-md-3 col-sm-4">
                            <Img
                              className="img-fluid "
                              height="200"
                              width="200"
                              src="images/slides/euflag.jpg"
                              alt="workwell soluations bd "
                            />
                          </div>
                          <div className="col-lg-8 col-md-9 col-sm-8">
                            <div className="pt-15">
                              <p>
                                Our foundation is built upon values that guide
                                our every action and decision:
                              </p>
                              <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li>
                                  <i className="far fa-check-circle"></i>
                                  <div className="ttm-list-li-content">
                                    <b>Integrity & Honesty: </b> We uphold the
                                    highest standards of integrity in all our
                                    actions. Our word is our bond, and we ensure
                                    that our services are transparent, genuine,
                                    and reliable.
                                  </div>
                                </li>
                                <li>
                                  <i className="far fa-check-circle"></i>
                                  <div className="ttm-list-li-content">
                                    <b> Client-Centric Approach: </b> Every
                                    client is unique, and their dreams are
                                    precious. We listen, understand, and then
                                    act, ensuring our services are tailored to
                                    individual needs. Their satisfaction is our
                                    ultimate measure of success.
                                  </div>
                                </li>
                                <li>
                                  <i className="far fa-check-circle"></i>
                                  <div className="ttm-list-li-content">
                                    <b> Excellence & Quality: </b> We
                                    relentlessly pursue excellence in all we do,
                                    every day. By offering top-notch services,
                                    we aim to exceed expectations every single
                                    time.
                                  </div>
                                </li>
                                <li>
                                  <i className="far fa-check-circle"></i>
                                  <div className="ttm-list-li-content">
                                    <b> Respect & Empathy: </b>
                                    Every client's journey is personal to us. We
                                    treat each individual with the utmost
                                    respect, empathy, and understanding,
                                    appreciating the trust they place in our
                                    hands.
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
                <p className="my-lg-5 my-4">
                  <i>
                    At Workwell Solutions BD, we don’t just provide services; we
                    craft futures, nurture dreams, and foster global
                    partnerships. Join us in our quest to make European
                    employment dreams a reality for all.
                  </i>
                </p>
              </div>
              <div className="col-lg-6">
                <div className="mr-30 mt-30 mt-lg-60 mb-lg-30">
                  <div className="d-flex">
                    <Img
                      className="img-fluid  "
                      height="200"
                      width="200"
                      src="images/slides/19.jpg"
                      alt="workwell soluations bd "
                    />
                    <div className="flex-basis-auto ml_180 mr_30 mt_30 z-index_1">
                      <Img
                        className="img-fluid  "
                        height="200"
                        width="200"
                        src="images/slides/18.jpg"
                        alt="workwell soluations bd "
                      />
                    </div>
                    <div className="d-flex justify-content-end flex-column ml_180 mr_30 mb_35 z-index_1"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* about-section end */}

        {/* team-section */}
        <section className="ttm-row team-section bg-theme-GreyColor clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h2 className="title">
                      What Sets Us{" "}
                      <span className="text-theme-SkinColor">Apart</span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* Slider */}

            <div className="row">
              <div className="col-md-4 col-12">
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Extensive Network</h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Our long-standing partnerships with European employers
                      give our clients a competitive edge in the job market.
                    </p>
                  </div>
                </div>

                {/* featured-imagebox end */}
              </div>
              <div className="col-md-4 col-12">
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Holistic Support</h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      From initial consultation to post-arrival assistance, our
                      end-to-end support ensures smooth transitions for our
                      clients.
                    </p>
                  </div>
                </div>

                {/* featured-imagebox end */}
              </div>
              <div className="col-md-4 col-12">
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Skill Development</h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Recognizing the importance of being job-ready, empowering
                      our clients with skills that are in demand in the European
                      job market.
                    </p>
                  </div>
                </div>

                {/* featured-imagebox end */}
              </div>
            </div>

            {/* Slider end */}
          </div>
        </section>
        {/* team-section end */}

        {/* padding_bottom_zero-section */}
        <section
          className="ttm-row padding_bottom_zero-section bg-img6 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix "
          style={{
            backgroundImage: `url(${workweeljobvisacover})`,
            backgroundSize: "cover",
          }}
        >
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                <div className="pt-80 pt-md-30 text-center">
                  <div className="ttm-play-icon-btn">
                    <div className="ttm-play-icon-animation">
                      <a>
                        <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-white ttm-icon_element-size-md ttm-icon_element-style-rounded">
                          <Video className="fa fa-play text-theme-SkinColor" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-7">
                <div className="d-flex pt-80 pt-md-80">
                  <div className="p-15 w-100 bg-theme-SkinColor text-center">
                    {/* fid */}
                    <div className="ttm-fid inside ttm-fid-view-topicon">
                      <div className="ttm-fid-contents">
                        <h4>
                          <CountUp
                            start={0}
                            end={458}
                            duration={20}
                            delay={2}
                          />
                        </h4>
                        <h3 className="ttm-fid-title">Completed Projects</h3>
                      </div>
                    </div>
                    {/* fid end */}
                  </div>
                  <div className="p-15 w-100 bg-theme-DarkColor text-center">
                    {/* fid */}
                    <div className="ttm-fid inside ttm-fid-view-topicon">
                      <div className="ttm-fid-contents">
                        <h4>
                          <CountUp
                            start={0}
                            end={25656}
                            duration={20}
                            delay={2}
                          />{" "}
                          +
                        </h4>
                        <h3 className="ttm-fid-title">Working Hours!</h3>
                      </div>
                    </div>
                    {/* fid end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* padding_bottom_zero-section end */}

        {/* job-list-section */}
        <section className="ttm-row job-list-section ttm-bg clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      our <span className="text-theme-SkinColor">Story!</span>
                    </h3>
                    <h2 className="title">Know more about</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                <div id="timeline-story" className="timeline-story-wrapper">
                  {/* timeline */}
                  <div className="timeline">
                    <div className="timeline-panel">
                      <div className="timeline-shape">
                        <span className="shape-circle"></span>
                        <span className="shape-image">
                          <Img
                            className="img-fluid"
                            src="images/dotted_shape.png"
                          />
                        </span>
                      </div>
                      <div className="timeline-body">
                        <div className="timeline-date">APR 15 2022</div>
                        <h3 className="title">Origins</h3>
                        <p>
                          In the heart of Dhaka, Workwell Solutions BD began as
                          a simple idea. A desire to bridge the distance between
                          European job opportunities and the immense talent pool
                          of Bangladesh. From a small office to a recognized
                          name, our journey is a testament to dreams realized
                          through determination.
                        </p>
                      </div>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-shape">
                        <span className="shape-circle"></span>
                        <span className="shape-image">
                          <Img
                            className="img-fluid"
                            src="images/dotted_shape.png"
                          />
                        </span>
                      </div>
                      <div className="timeline-body">
                        <div className="timeline-date">Jnu 11 2014</div>
                        <h3 className="title">Team Collaboration</h3>
                        <p>
                          Understanding that the recruitment process doesn't
                          rely on just one individual, we built a diverse and
                          dedicated team. On this day, our collaborative spirit
                          took root, bringing together experts from various
                          fields, ensuring a holistic approach to overseas job
                          placement.
                        </p>
                      </div>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-shape">
                        <span className="shape-circle"></span>
                        <span className="shape-image">
                          <Img
                            className="img-fluid"
                            src="images/dotted_shape.png"
                          />
                        </span>
                      </div>
                      <div className="timeline-body">
                        <div className="timeline-date">AUG 15 2016</div>
                        <h3 className="title">An Outsourcing Firm</h3>
                        <p>
                          An Outsourcing Firm The world was changing, and with
                          it, global firms faced challenges in hiring
                          experienced executives who could seamlessly integrate
                          into their unique cultures. Recognizing this gap,
                          Workwell Solutions BD positioned itself as a bridge,
                          connecting top-tier talent with companies worldwide.
                        </p>
                      </div>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-shape">
                        <span className="shape-circle"></span>
                        <span className="shape-image">
                          <Img
                            className="img-fluid"
                            src="images/dotted_shape.png"
                          />
                        </span>
                      </div>
                      <div className="timeline-body">
                        <div className="timeline-date">Sept 15 2016</div>
                        <h3 className="title">The Leading Group</h3>
                        <p>
                          The Leading Group Our commitment to excellence and
                          innovation didn't go unnoticed. By this day, we had
                          carved out a niche for ourselves, becoming a leader in
                          overseas recruitment. Our forums of exchange became
                          platforms for innovative solutions, setting industry
                          standards..
                        </p>
                      </div>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-shape">
                        <span className="shape-circle"></span>
                        <span className="shape-image">
                          <Img
                            className="img-fluid"
                            src="images/dotted_shape.png"
                          />
                        </span>
                      </div>
                      <div className="timeline-body">
                        <a
                          className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey ttm-icon-btn-right"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          Read More<i className="ti ti-angle-double-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* timeline end */}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* job-list end */}

        {/* padding_zero-section */}
        <section className="ttm-row padding_bottom_zero-section bg-layer-equal-height mt_30 mt_lg-60 clearfix">
          <div className="container p-0">
            <div className="row g-0">
              <div className="col-lg-8">
                <div className="ttm-bg ttm-bg ttm-col-bgimage-yes col-bg-img-five ttm-left-span spacing-3">
                  <div
                    className="ttm-col-wrapper-bg-layer ttm-bg-layer "
                    style={{
                      backgroundImage: `url(${workweeljobvisacovereu})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="ttm-col-wrapper-bg-layer-inner"></div>
                  </div>
                  <div className="layer-content">
                    {/* section title  */}
                    <div className="section-title">
                      <div className="title-header">
                        <h3>WHO WE ARE</h3>
                        <h2 className="title">
                          Workwell Solutions BD – Where Dreams Meet
                          Opportunities.
                        </h2>
                      </div>
                      <div className="title-desc">
                        <small className="text-dark">
                          Workwell Solutions BD is more than just a recruitment
                          firm; we are architects of dreams, curators of
                          opportunity, and a bridge to new horizons. Founded
                          with a deep-rooted passion to unlock doors to European
                          employment for our clients, we have grown
                          exponentially, not just in size but in our vision and
                          impact. We believe that talent knows no boundaries.
                          With the world turning into a global village, the
                          right job for an individual might lie across the
                          oceans, and we are here to ensure they reach it. Every
                          individual we work with brings a unique dream, a
                          distinct aspiration, and we are dedicated to nurturing
                          these dreams, ensuring they transform into tangible
                          realities. Our journey, filled with challenges,
                          learnings, successes, and transformations, has only
                          made us more resolute in our mission: to be the most
                          trusted partner for those seeking to transform their
                          European job dreams into reality. Join us and become a
                          part of the Workwell Solutions BD legacy.
                        </small>
                      </div>
                    </div>
                    <div className="pt-10 pb-90 pb-lg-0">
                      <p className="text-white">
                        We have won 27 more awards in our career.{" "}
                        <a href={process.env.PUBLIC_URL + "/"}>
                          <b>
                            <u>View All Awards</u>
                          </b>
                        </a>
                      </p>
                    </div>
                    {/* section title end  */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                {/* col-img-img-six */}
                <div className="ttm-bg ttm-col-bgimage-yes col-bg-img-six ttm-right-span z-index-2 ml_120 ml_lg-0">
                  <div
                    className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                    style={{
                      backgroundImage: "url( images/slides/15.jpg)",
                    }}
                  ></div>
                  <div className="layer-content"></div>
                  <Img
                    className="img-fluid col-bg-img-res"
                    src="images/slides/18.jpg"
                    alt="bgimage"
                  />
                </div>
                {/* col-img-bg-img-six end */}
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* padding_zero-section end */}

        {/* testimonial-section */}
        <section className="ttm-row testimonial-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-11">
                {/* section title */}
                <div className="section-title style2">
                  <div className="title-header">
                    <h3>Happy Clients</h3>
                    <h2 className="title">Listen Clients Stories</h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      We have over{" "}
                      <span className="text-theme-SkinColor"> 10 years</span>
                      of experience in workforce optimization and talent
                      acquisition, Workwell Solutions BD focuses on client
                      satisfaction, matching the right talent to the right
                      opportunity. We're more than recruiters; we're career
                      architects.{" "}
                    </p>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            <Slider
              className="row slick_slider slick-arrows-style2"
              {...slick_slider}
              slidesToShow={3}
              arrows={true}
            >
              <div className="col-lg-12">
                {/* testimonials */}
                <div className="testimonials ttm-testimonial-box-view-style1">
                  <div className="testimonial-content">
                    <blockquote className="testimonial-text">
                      "Workwell Solutions BD helped me secure a fantastic job in
                      Europe. Their team guided me throughout the process, and
                      their support was invaluable. I am now settled and
                      enjoying my new life. Thank you, Workwell Solutions BD!"
                    </blockquote>
                    <div className="star-ratings">
                      <ul className="rating">
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="testimonial-bottom">
                    <div className="testimonial-avatar">
                      <div className="testimonial-img">
                        <Img
                          className="img-fluid"
                          src="images/slides/01.jpg"
                          alt="Romania Work Visa "
                        />
                      </div>
                    </div>
                    <div className="testimonial-caption">
                      <label>Electrician</label>
                      <h3> Asif Mahmud </h3>
                    </div>
                  </div>
                </div>{" "}
                {/* testimonials end */}
              </div>
              <div className="col-lg-12">
                {/* testimonials */}
                <div className="testimonials ttm-testimonial-box-view-style1">
                  <div className="testimonial-content">
                    <blockquote className="testimonial-text">
                      "I couldn't have found my dream job as a Refrigeration
                      Technicians in Europe without Workwell Solutions BD. Their
                      expertise and dedication made the entire process smooth
                      and stress-free. I highly recommend their services to
                      anyone seeking a job abroad."
                    </blockquote>
                    <div className="star-ratings">
                      <ul className="rating">
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="testimonial-bottom">
                    <div className="testimonial-avatar">
                      <div className="testimonial-img">
                        <Img
                          className="img-fluid"
                          src="images/slides/10.jpg"
                          alt="Romania Work Visa "
                        />
                      </div>
                    </div>
                    <div className="testimonial-caption">
                      <label>Refrigeration Technicians</label>
                      <h3>Anamul Haque </h3>
                    </div>
                  </div>
                </div>{" "}
                {/* testimonials end */}
              </div>
              <div className="col-lg-12">
                {/* testimonials */}
                <div className="testimonials ttm-testimonial-box-view-style1">
                  <div className="testimonial-content">
                    <blockquote className="testimonial-text">
                      "I couldn't have found my dream job as a Refrigeration
                      Technicians in Europe without Workwell Solutions BD. Their
                      expertise and dedication made the entire process smooth
                      and stress-free. I highly recommend their services to
                      anyone seeking a job abroad."
                    </blockquote>
                    <div className="star-ratings">
                      <ul className="rating">
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="testimonial-bottom">
                    <div className="testimonial-avatar">
                      <div className="testimonial-img">
                        <Img
                          className="img-fluid"
                          src="images/slides/08.jpg"
                          alt="testimonial-img"
                        />
                      </div>
                    </div>
                    <div className="testimonial-caption">
                      <label>Plumber </label>
                      <h3>Rayan Sarker </h3>
                    </div>
                  </div>
                </div>{" "}
                {/* testimonials end */}
              </div>
              <div className="col-lg-12">
                {/* testimonials */}
                <div className="testimonials ttm-testimonial-box-view-style1">
                  <div className="testimonial-content">
                    <blockquote className="testimonial-text">
                      "Workwell Solutions BD provided exceptional support during
                      my job search as a Plumber. Their training programs and
                      pre-departure assistance prepared me for a successful
                      transition to Europe. I am grateful for their help and
                      support."
                    </blockquote>
                    <div className="star-ratings">
                      <ul className="rating">
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="active">
                          <i className="fa fa-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="testimonial-bottom">
                    <div className="testimonial-avatar">
                      <div className="testimonial-img">
                        <Img
                          className="img-fluid"
                          src="images/slides/03.jpg"
                          alt="testimonial-img"
                        />
                      </div>
                    </div>
                    <div className="testimonial-caption">
                      <label>Restaurant Manager</label>
                      <h3>Mohammed Ashraful </h3>
                    </div>
                  </div>
                </div>{" "}
                {/* testimonials end */}
              </div>
            </Slider>
          </div>
        </section>
        {/* testimonial-section end */}

        {/* contact-section */}
        <section className="ttm-row contact-section bg-theme-GreyColor clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* section-title */}
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>Contact us</h3>
                    <h2 className="title">Get In Touch With Us</h2>
                  </div>
                </div>
                {/* section-title end */}
              </div>
            </div>
            {/* row */}
            <div className="row">
              <div className="col-lg-6">
                <div className="pr-30 pr-md-0 mt-15">
                  <div className="ttm_single_image-wrapper mb-20">
                    <Img
                      className="img-fluid w-100"
                      src="images/images/req01.jpg"
                      alt="single_06"
                    />
                  </div>
                  <p className="mb-30">
                    Have questions or want to kick start your European job
                    journey? Get in touch:
                  </p>

                  <div className="d-flex align-items-center">
                    <div
                      className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm 
                                    ttm-icon_element-style-round mb-0"
                    >
                      <i className="fa fa-phone"></i>
                    </div>
                    <div className="pl-15">
                      <h6 className="mb-5">Call Anytime</h6>
                      <p className="featured-desc mb-0">
                        <a href="tel:+8801988734762"> +8801988734762</a>
                      </p>
                    </div>
                  </div>

                  <div className="ttm-horizontal_sep width-100 mt-20 mb-20"></div>

                  <div className="d-flex align-items-center">
                    <div
                      className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm 
                                    ttm-icon_element-style-round mb-0"
                    >
                      <i className="fa fa-globe"></i>
                    </div>
                    <div className="pl-15">
                      <h6 className="mb-5">Send Email</h6>
                      <p className="featured-desc mb-0">
                        <a href="mailto:info@workwellsolutionsbd.com">
                          info@workwellsolutionsbd.com
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="ttm-horizontal_sep width-100 mt-20 mb-20"></div>

                  <div className="d-flex align-items-center">
                    <div
                      className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-sm 
                                    ttm-icon_element-style-round mb-0"
                    >
                      <i className="ti ti-home"></i>
                    </div>
                    <div className="pl-15">
                      <h6 className="mb-5">Visit Office</h6>
                      <p className="featured-desc mb-0">
                        #05 Road #12 House #7th Level, Bosila Model Town,
                        Mohammadpur, Dhaka, Bangladesh
                      </p>
                    </div>
                  </div>
                  {/*  featured-icon-box end */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-WhiteColor z-index-2 p-40 p-lg-30 mt-15 mt-lg-30">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content">
                    <form id="contact_form" className="contact_form wrap-form">
                      <div className="row ttm-boxes-spacing-15px">
                        <div className="col-md-12 ttm-box-col-wrapper">
                          <label>
                            <input
                              name="name"
                              type="text"
                              placeholder="Your Name"
                              required="required"
                            />
                          </label>
                        </div>
                        <div className="col-md-6 ttm-box-col-wrapper">
                          <label>
                            <input
                              name="email"
                              type="text"
                              placeholder="Your Email"
                              required="required"
                            />
                          </label>
                        </div>
                        <div className="col-md-6 ttm-box-col-wrapper">
                          <label>
                            <input
                              name="subject"
                              type="text"
                              placeholder="Subject"
                              required="required"
                            />
                          </label>
                        </div>
                        <div className="col-md-12 ttm-box-col-wrapper">
                          <label>
                            <input
                              name="phone"
                              type="text"
                              placeholder="Phone Number"
                              required="required"
                            />
                          </label>
                        </div>
                        <div className="col-md-12 ttm-box-col-wrapper">
                          <label>
                            <textarea
                              name="message"
                              rows="9"
                              placeholder="Message"
                              required="required"
                            ></textarea>
                          </label>
                        </div>
                      </div>
                      <button
                        className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                        type="submit"
                      >
                        send a message!
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact-section end */}

        {/* google_map */}
        <div id="google_map" className="google_map">
          <div className="map_container clearfix">
            <div id="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.8560744729793!2d90.35152417596498!3d23.75251128869413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf5c53c0dd0b%3A0xcec8e3191933b82!2sQ935%2BQCC%2C%20Dhaka!5e0!3m2!1sen!2sbd!4v1693846338127!5m2!1sen!2sbd"
                width="100%"
                height="450"
              ></iframe>
            </div>
          </div>
        </div>
        {/* google_map end */}

        {/* action-section */}
        <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-md-flex align-items-center justify-content-between">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content style2">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                        <i className="flaticon flaticon-recruitment-5"></i>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-desc">
                        <p>
                          {" "}
                          <i>
                            Workwell Solutions BD – Where Dreams Meet
                            Opportunities.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                    href={process.env.PUBLIC_URL + "/"}
                  >
                    Hiring Now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* action-section end */}

        <Footer />
      </div>
    );
  }
}

export default About_us;
