import React, { Component } from "react";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
import { Footer } from "../components/layout/Footer";
import Img from "./Img";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Helmet } from "react-helmet";

export class Job_list extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      currentSlide: 0,
    };
    this.state = {
      jobTitleOrKeywords: "",
      location: "Europe",
      jobs: [
        { title: "Building & Construction Visa", location: "Romania" },
        { title: "Welders Visa", location: "Romania" },
        { title: "Plumbers Visa ", location: "CZ Repulbic" },
        { title: "Refrigeration Technicians Visa ", location: "Europe" },
        { title: "Electricians Job Visa", location: "Romania ,Europe" },
        { title: "Drivers Work Visa", location: "Malta" },
        { title: "Printers Visa", location: "Europe" },
        { title: "Agriculture Visa", location: "Romania" },
        { title: "Restaurant Visas", location: "Europe" },
        { title: "Work Visas", location: "Europe" },
        { title: "Big Payer Jobs", location: "Europe" },
        { title: "Construction Visa", location: "Europe" },
      ],
      filteredJobs: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const jobTitleOrKeywords = document.getElementById("filter").value;
    const location = document.getElementById("txtlocation").value;

    // Check if input values are empty
    if (jobTitleOrKeywords.trim() === "") {
      // alert("Please Type your dream jobs !");
      Swal.fire(
        "European Job Opportunities?",
        "We are committed to providing personalized services to our clients? Please enter your Skill Jobs Name",
        "question"
      );
    } else {
      this.filterJobs(jobTitleOrKeywords, location);
    }
  };

  filterJobs = (jobTitleOrKeywords, location) => {
    const filteredJobs = this.state.jobs.filter(
      (job) =>
        job.title.toLowerCase().includes(jobTitleOrKeywords.toLowerCase()) &&
        job.location.toLowerCase().includes(location.toLowerCase())
    );

    this.setState({ filteredJobs });
  };

  handleAfterChange = (index) => {
    console.log("after change", index);
    this.setState({
      currentSlide: index,
    });
  };
  render() {
    return (
      <div className="site-main">
        <Header />

        {/* PageHeader */}
        <PageHeader
          title="job list"
          breadcrumb="job"
          className="pb-65 pb-lg-0"
        />
        <Helmet>
          <title>
            {" "}
            Job list- WorkWell Solutions BD -Europe Work Visa Guide{" "}
          </title>
          <meta
            name="description"
            content="Explore opportunities for Europe Work Visa with WorkWell Solutions BD. Learn about Romania and Poland Work Visa, Work Permit in Europe, Malta and Croatia Job Visa and more."
          />
          <meta
            name="keywords"
            content="workwell, workwell solutions, workwell solutions bd, workwellsolutionsbd, vandary, vandary faruk, europe work visa, romania work visa, poland work visa, work permit europe, malta job visa, croatia work visa, job in europe, bangladesh europe job, estonia job, workvisaeurope, jobsineurope, romania job visa, restaurant job, agriculture job visa europe, italy job visa, italy agriculture visa, work well solution bd, workwellsolution, workwellsolutionsbd, skyasiabd, skyasia, sky asia, job visa europe"
          />
        </Helmet>
        {/* PageHeader end */}

        {/* form */}
        <div className="form-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-30 p-lg-20 mt_60 mt-xl-50">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content">
                    <form
                      id="search_Form"
                      className="search_Form wrap-form m-1 border rounded rounded-3 overflow-hidden"
                      onSubmit={this.handleSubmit}
                    >
                      <label>
                        <i className="ti ti-search"></i>
                        <input
                          type="text"
                          id="filter"
                          placeholder="Job Title or Keywords"
                        />
                      </label>
                      <label>
                        <i className="ti ti-location-pin"></i>
                        <input
                          type="text"
                          name="location"
                          id="txtlocation"
                          placeholder="Europe Union"
                          disabled
                        />
                      </label>
                      <button
                        className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                        type="submit"
                      >
                        Find Jobs
                      </button>
                    </form>

                    <div>
                      {this.state.filteredJobs.map((job, index) => (
                        <div key={index}>
                          <h3>{job.title}</h3>
                          <p>{job.location}</p>
                          <a
                            className="btn btn-dark btn-inline ttm-btn-size-md ttm-btn-color-dark"
                            href={process.env.PUBLIC_URL + "/job_list"}
                          >
                            Apply Jobs!
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* form end */}

        <div className="ttm-row sidebar job-sidebar clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                <div className="job_list-widget">
                  <aside className="widget job-widget">
                    <h3 className="widget-title">
                      <i className="flaticon flaticon-calendar-1"></i>Date
                      Posted
                    </h3>
                    <form id="list1" className="list-filter">
                      <div onChange={this.onChangeValue}>
                        <label className="radio">
                          <input type="radio" value="Today" name="post_date" />
                          Today
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Last 7 days"
                            defaultChecked
                            name="post_date"
                          />
                          Last 7 days
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Last 14 days"
                            name="post_date"
                          />
                          Last 14 days
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Last 21 days"
                            name="post_date"
                          />
                          Last 21 days
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Last 30 days"
                            name="post_date"
                          />
                          Last 30 days
                        </label>
                      </div>
                    </form>
                  </aside>
                  <aside className="widget job-widget">
                    <h3 className="widget-title">
                      <i className="flaticon flaticon-subfolder-1"></i>
                      Categories
                    </h3>
                    <form
                      id="list2"
                      onSubmit={this.formSubmit}
                      className="list-filter"
                    >
                      <div onChange={this.onChangeValue}>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Agriculture"
                            defaultChecked
                            name="categories"
                          />
                          Agriculture
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Driver"
                            name="categories"
                          />
                          Driver
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Building & Construction"
                            name="categories"
                          />
                          Building & Construction
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Welding"
                            name="categories"
                          />
                          Welding
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Plumbing"
                            name="categories"
                          />
                          Plumbing
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Electricianmain"
                            name="categories"
                          />
                          Electricianmain
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Restaurant Visa"
                            name="categories"
                          />
                          Restaurant Visa
                        </label>
                      </div>
                    </form>
                  </aside>
                  <aside className="widget job-widget">
                    <h3 className="widget-title">
                      <i className="flaticon flaticon-expert"></i>Experince
                    </h3>
                    <form
                      id="list3"
                      onSubmit={this.formSubmit}
                      className="list-filter"
                    >
                      <div onChange={this.onChangeValue}>
                        <label className="radio">
                          <input
                            type="radio"
                            value="0Year to 1Year"
                            name="ex_year"
                          />
                          0Year to 1Year
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="1Year to 2Year"
                            name="ex_year"
                          />
                          1Year to 2Year
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="2Year to 3Year"
                            name="ex_year"
                          />
                          2Year to 3Year
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="3Year or more"
                            defaultChecked
                            name="ex_year"
                          />
                          3Year or more
                        </label>
                      </div>
                    </form>
                  </aside>
                  <aside className="widget job-widget">
                    <h3 className="widget-title">
                      <i className="flaticon flaticon-gender"></i>Gender
                    </h3>
                    <form
                      id="list4"
                      onSubmit={this.formSubmit}
                      className="list-filter"
                    >
                      <div onChange={this.onChangeValue}>
                        <label className="radio">
                          <input
                            type="radio"
                            value="male"
                            defaultChecked
                            name="gender"
                          />
                          male
                        </label>
                        <label className="radio">
                          <input type="radio" value="female" name="gender" />
                          female
                        </label>
                      </div>
                    </form>
                  </aside>
                </div>
                <aside className="widget widget-download">
                  <ul className="download">
                    <li>
                      <a href="/workwell">Download.pdf</a>
                      <i className="ti ti-files"></i>
                    </li>
                    <li>
                      <a href="/workwell">Download.txt</a>
                      <i className="ti ti-files"></i>
                    </li>
                  </ul>
                </aside>
                <aside className="widget widget-banner text-theme-WhiteColor">
                  <div className="ttm-col-bgcolor-yes bg-theme-DarkColor text-theme-WhitColor col-bg-img-seven ttm-col-bgimage-yes ttm-bg p-40">
                    <div
                      className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"
                      style={{
                        backgroundImage: `url(images/slides/vandari1.jpg)`,
                      }}
                    >
                      <div className="ttm-col-wrapper-bg-layer-inner bg-theme-DarkColor"></div>
                    </div>
                    <div className="layer-content text-center">
                      <div className="widget-banner-inner">
                        <h3 className="mb-15">
                          Make a Difference with Online Resume!
                        </h3>
                        <p className="mb-30">
                          Your Resume in Minutes with Jobs Resume Assistant is
                          Ready!
                        </p>
                        <a
                          className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                          href={process.env.PUBLIC_URL + "/"}
                        >
                          appoinments!
                        </a>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="col-lg-8 content-area">
                <div className="row">
                  <div className="col-md-12">
                    <div className="showing-result-block d-sm-flex align-items-center justify-content-between">
                      <span className="showing-result">
                        Showing 1–10 of 50 Jobs Results :
                      </span>
                      <form>
                        <div className="sort-by">
                          Sort By:
                          <select defaultValue="">
                            <option value="" disabled>
                              A to Z
                            </option>
                            <option value="popularity">a</option>
                            <option value="rating">b</option>
                            <option value="date">c</option>
                            <option value="price">d</option>
                            <option value="price-desc">e</option>
                          </select>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                      <div className="featured-thumbnail">
                        <Img src="images/country/Flag_of_Lithuania.png" />
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h3>
                            <a href={process.env.PUBLIC_URL + "/Job_details"}>
                              Vacancy For the Agriculture
                            </a>
                          </h3>
                        </div>
                        <div className="featured-desc">
                          <p>Published 2Days Ago.</p>
                        </div>
                        <div className="featured-bottom">
                          <div className="job-meta">
                            <span>
                              <i className="fa fa-map-marker-alt"></i>Lithuania
                            </span>
                            <span>
                              <i className="fa fa-filter"></i>Agriculture Jobs
                            </span>
                          </div>
                          <div className="job-time">
                            <span className="green">full time</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* featured-imagebox end */}
                  </div>
                  <div className="col-lg-12 col-md-12">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                      <div className="featured-thumbnail">
                        <Img src="images/country/Flag_of_Romania.svg.png" />
                        <div className="required-tag">Urgent</div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h3>
                            <a href={process.env.PUBLIC_URL + "/Job_details"}>
                              Vacancy For the Restaurant Visa
                            </a>
                          </h3>
                        </div>
                        <div className="featured-desc">
                          <p>Published 2Days Ago.</p>
                        </div>
                        <div className="featured-bottom">
                          <div className="job-meta">
                            <span>
                              <i className="fa fa-map-marker-alt"></i>Romania
                            </span>
                            <span>
                              <i className="fa fa-filter"></i>Restaurant Jobs
                            </span>
                          </div>
                          <div className="job-time">
                            <span className="blue">Full time</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* featured-imagebox end */}
                  </div>
                  <div className="col-lg-12 col-md-12">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                      <div className="featured-thumbnail">
                        <Img src="images/country/Civil_ensign_of_Croatia.svg.png" />
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h3>
                            <a href={process.env.PUBLIC_URL + "/Job_details"}>
                              <a href={process.env.PUBLIC_URL + "/Job_details"}>
                                Opening For Electricianmain
                              </a>
                            </a>
                          </h3>
                        </div>
                        <div className="featured-desc">
                          <p>Published 10 Days Ago.</p>
                        </div>
                        <div className="featured-bottom">
                          <div className="job-meta">
                            <span>
                              <i className="fa fa-map-marker-alt"></i>Croatia
                            </span>
                            <span>
                              <i className="fa fa-filter"></i>Electricianmain
                              Jobs
                            </span>
                          </div>
                          <div className="job-time">
                            <span className="deep-orange">full time</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* featured-imagebox end */}
                  </div>
                  <div className="col-lg-12 col-md-12">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                      <div className="featured-thumbnail">
                        <Img src="images/country/Flag_of_Malta.svg.png" />
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h3>
                            <a href={process.env.PUBLIC_URL + "/Job_details"}>
                              <a href={process.env.PUBLIC_URL + "/Job_details"}>
                                Opening For Plumbing
                              </a>
                            </a>
                          </h3>
                        </div>
                        <div className="featured-desc">
                          <p>Published 19 Days Ago.</p>
                        </div>
                        <div className="featured-bottom">
                          <div className="job-meta">
                            <span>
                              <i className="fa fa-map-marker-alt"></i>Malta
                            </span>
                            <span>
                              <i className="fa fa-filter"></i>Plumbing Jobs
                            </span>
                          </div>
                          <div className="job-time">
                            <span className="green">full time</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* featured-imagebox end */}
                  </div>
                  <div className="col-lg-12 col-md-12">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                      <div className="featured-thumbnail">
                        <Img src="images/country/Flag_of_the_Czech_Republic.svg.png" />
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h3>
                            <a href={process.env.PUBLIC_URL + "/Job_details"}>
                              Job Vacancy For Building & Construction
                            </a>
                          </h3>
                        </div>
                        <div className="featured-desc">
                          <p>Published 2Days Ago.</p>
                        </div>
                        <div className="featured-bottom">
                          <div className="job-meta">
                            <span>
                              <i className="fa fa-map-marker-alt"></i>Czech
                              Republic
                            </span>
                            <span>
                              <i className="fa fa-filter"></i>Building &
                              Construction Jobs
                            </span>
                          </div>
                          <div className="job-time">
                            <span className="danger-color">Full time</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* featured-imagebox end */}
                  </div>

                  <div className="col-lg-12">
                    <div className="job-pagination-block">
                      <spam className="page-nav-link cursor-pointer">prev</spam>
                      <span className="page-nav-link current cursor-pointer">
                        1
                      </span>
                      <span className="page-nav-link cursor-pointer">3</span>
                      <span className="page-nav-link cursor-pointer">....</span>
                      <span className="page-nav-link cursor-pointer">next</span>
                    </div>
                  </div>
                </div>
                {/* row end */}
              </div>
            </div>
            {/* row end */}
          </div>
        </div>

        {/* action-section */}
        <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-md-flex align-items-center justify-content-between">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content style2">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                        <i className="flaticon flaticon-recruitment-5"></i>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>Incredible Recruitment & Staffing Agency</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We have over 30 years experience oft Business porro
                          qusquam dol ipsum quia dolor sit amet.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                    href={process.env.PUBLIC_URL + "/"}
                  >
                    Hiring Now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* action-section end */}

        <Footer />
      </div>
    );
  }
}

export default Job_list;
