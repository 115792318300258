import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Pagination,
} from "react-bootstrap";
import Header from "../components/layout/Header";
import Img from "./Img";
import { Footer } from "../components/layout/Footer";
import PageHeader from "../components/layout/PageHeader";

function EuropeWorkVisa() {
  const [sort, setSort] = useState("");
  // State to manage the current page number
  const [active, setActive] = useState(1);
  // Assuming you have 3 pages, adjust as needed
  const totalPages = 3;

  const handleSortChange = (event) => {
    setSort(event.target.value);
    // Handle the sorting logic here...
  };
  return (
    <main>
      <Helmet>
        <title>Europe Work Visa Guide - WorkWell Solutions BD</title>
        <meta
          name="description"
          content="Explore opportunities for Europe Work Visa with WorkWell Solutions BD. Learn about Romania and Poland Work Visa, Work Permit in Europe, Malta and Croatia Job Visa and more."
        />
        <meta
          name="keywords"
          content="workwell, workwell solutions, workwell solutions bd, workwellsolutionsbd, vandary, vandary faruk, europe work visa, romania work visa, poland work visa, work permit europe, malta job visa, croatia work visa, job in europe, bangladesh europe job, estonia job, workvisaeurope, jobsineurope, romania job visa, restaurant job, agriculture job visa europe, italy job visa, italy agriculture visa, work well solution bd, workwellsolution, workwellsolutionsbd, skyasiabd, skyasia, sky asia, job visa europe"
        />
      </Helmet>
      <Header></Header>
      <PageHeader
        title="Europe Work Visa Guide"
        breadcrumb="Europe Work Visa Guide"
        className="pb-65 pb-lg-0"
      />
      <Container>
        <Row className="my-4">
          <Col>
            <div className="section-title">
              <div className="title-header">
                <h3>
                  Great <span className="text-theme-SkinColor">Search! </span>
                </h3>
                <h2 className="title ">
                  Europe Work Visa Guide by WorkWell Solutions BD
                </h2>
              </div>
              <div className="title-desc">
                <p>
                  Unlock extensive opportunities and insights on obtaining a
                  work visa in Europe. Learn about varied job sectors such as
                  Agriculture, Restaurant, Electricianmain, and Plumbing in
                  countries like Romania, Poland, Croatia, and Malta. Secure
                  your future with trusted and reliable solutions provided by
                  WorkWell Solutions BD.
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <Card className="shadow">
              <Card.Body>
                <Card.Title>
                  <h2>Special Work Visa Program</h2>
                </Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <strong>100% Visa Assurance.</strong> Processing Time: 5 - 6
                    months.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Employment Opportunities:</strong> Concurrently,
                    opportunities in Factory Worker, Food Delivery, Farm House,
                    and Agriculture jobs are available.
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Program:</strong> Where Dreams Meet Opportunities.
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="my-4">
          <Col md={6}>
            <Card className="shadow mb-4">
              <Card.Body>
                <Card.Title>
                  <h3>Our Package Includes:</h3>
                </Card.Title>
                <ListGroup variant="flush">
                  <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                    <ListGroup.Item>
                      {" "}
                      <li>
                        <i className="far fa-check-circle"></i>
                        <div className="ttm-list-li-content">
                          1 Year Tuition Fee (For Students only)
                        </div>
                      </li>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <li>
                        <i className="far fa-check-circle"></i>
                        <div className="ttm-list-li-content">
                          1 Year TRC (Temporary Residence Card)
                        </div>
                      </li>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <li>
                        <i className="far fa-check-circle"></i>
                        <div className="ttm-list-li-content">
                          1 Year Health Insurance
                        </div>
                      </li>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <li>
                        <i className="far fa-check-circle"></i>
                        <div className="ttm-list-li-content">
                          Offer Letter and Ministry Approval
                        </div>
                      </li>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <li>
                        <i className="far fa-check-circle"></i>
                        <div className="ttm-list-li-content">Flight Ticket</div>
                      </li>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <li>
                        <i className="far fa-check-circle"></i>
                        <div className="ttm-list-li-content">
                          Job Management
                        </div>
                      </li>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <li>
                        <i className="far fa-check-circle"></i>
                        <div className="ttm-list-li-content">Medical</div>
                      </li>
                    </ListGroup.Item>
                  </ul>
                </ListGroup>
              </Card.Body>
            </Card>

            <Card className="shadow mb-4">
              <Card.Body>
                <Card.Title>
                  <h3>Payment System:</h3>
                </Card.Title>
                <Card.Text>
                  50,000 BDT with Passport. Another 50,000 BDT at the time of
                  Embassy Face. (The remaining amount after visa approval).
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="shadow">
              <Card.Body>
                <Card.Title>
                  <h3>Required Documents:</h3>
                </Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item>Passport</ListGroup.Item>
                  <ListGroup.Item>
                    Photo in European Size (35-45)
                  </ListGroup.Item>
                  <ListGroup.Item>SSC, HSC, Maxit Certificate</ListGroup.Item>
                  <ListGroup.Item>
                    Birth Certificate (In English)
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Work Experince Certificate (If Have ){" "}
                  </ListGroup.Item>
                </ListGroup>
                <Card.Text className="mt-3">
                  <strong>Note:</strong> All documents must be attested by the
                  Education Ministry and the Foreign Ministry.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <div className=" content-area">
              <div className="row">
                <div className="col-md-12">
                  <div className="showing-result-block d-sm-flex align-items-center justify-content-between">
                    <span className="showing-result">
                      Showing 1–10 of 50 Jobs Results :
                    </span>
                    <form>
                      <div className="sort-by">
                        Sort By:
                        <select value={sort} onChange={handleSortChange}>
                          <option value="AtoZ" disabled>
                            A to Z
                          </option>
                          <option value="a">a</option>
                          <option value="b">b</option>
                          <option value="c">c</option>
                          <option value="d">d</option>
                          <option value="e">e</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  {/* featured-imagebox */}
                  <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                    <div className="featured-thumbnail">
                      <Img src="images/country/Flag_of_Lithuania.png" />
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>
                          <a href={process.env.PUBLIC_URL + "/Job_details"}>
                            Vacancy For the Agriculture
                          </a>
                        </h3>
                      </div>
                      <div className="featured-desc">
                        <p>Published 2Days Ago.</p>
                      </div>
                      <div className="featured-bottom">
                        <div className="job-meta">
                          <span>
                            <i className="fa fa-map-marker-alt"></i>Lithuania
                          </span>
                          <span>
                            <i className="fa fa-filter"></i>Agriculture Jobs
                          </span>
                        </div>
                        <div className="job-time">
                          <span className="green">full time</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* featured-imagebox end */}
                </div>
                <div className="col-lg-12 col-md-12">
                  {/* featured-imagebox */}
                  <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                    <div className="featured-thumbnail">
                      <Img src="images/country/Flag_of_Romania.svg.png" />
                      <div className="required-tag">Urgent</div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>
                          <a href={process.env.PUBLIC_URL + "/Job_details"}>
                            Vacancy For the Restaurant Visa
                          </a>
                        </h3>
                      </div>
                      <div className="featured-desc">
                        <p>Published 2Days Ago.</p>
                      </div>
                      <div className="featured-bottom">
                        <div className="job-meta">
                          <span>
                            <i className="fa fa-map-marker-alt"></i>Romania
                          </span>
                          <span>
                            <i className="fa fa-filter"></i>Restaurant Jobs
                          </span>
                        </div>
                        <div className="job-time">
                          <span className="blue">Full time</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* featured-imagebox end */}
                </div>
                <div className="col-lg-12 col-md-12">
                  {/* featured-imagebox */}
                  <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                    <div className="featured-thumbnail">
                      <Img src="images/country/Civil_ensign_of_Croatia.svg.png" />
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>
                          <a href={process.env.PUBLIC_URL + "/Job_details"}>
                            <a href={process.env.PUBLIC_URL + "/Job_details"}>
                              Opening For Electricianmain
                            </a>
                          </a>
                        </h3>
                      </div>
                      <div className="featured-desc">
                        <p>Published 10 Days Ago.</p>
                      </div>
                      <div className="featured-bottom">
                        <div className="job-meta">
                          <span>
                            <i className="fa fa-map-marker-alt"></i>Croatia
                          </span>
                          <span>
                            <i className="fa fa-filter"></i>Electricianmain Jobs
                          </span>
                        </div>
                        <div className="job-time">
                          <span className="deep-orange">full time</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* featured-imagebox end */}
                </div>
                <div className="col-lg-12 col-md-12">
                  {/* featured-imagebox */}
                  <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                    <div className="featured-thumbnail">
                      <Img src="images/country/Flag_of_Malta.svg.png" />
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>
                          <a href={process.env.PUBLIC_URL + "/Job_details"}>
                            <a href={process.env.PUBLIC_URL + "/Job_details"}>
                              Opening For Plumbing
                            </a>
                          </a>
                        </h3>
                      </div>
                      <div className="featured-desc">
                        <p>Published 19 Days Ago.</p>
                      </div>
                      <div className="featured-bottom">
                        <div className="job-meta">
                          <span>
                            <i className="fa fa-map-marker-alt"></i>Malta
                          </span>
                          <span>
                            <i className="fa fa-filter"></i>Plumbing Jobs
                          </span>
                        </div>
                        <div className="job-time">
                          <span className="green">full time</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* featured-imagebox end */}
                </div>
                <div className="col-lg-12 col-md-12">
                  {/* featured-imagebox */}
                  <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                    <div className="featured-thumbnail">
                      <Img src="images/country/Flag_of_the_Czech_Republic.svg.png" />
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>
                          <a href={process.env.PUBLIC_URL + "/Job_details"}>
                            Job Vacancy For Building & Construction
                          </a>
                        </h3>
                      </div>
                      <div className="featured-desc">
                        <p>Published 2Days Ago.</p>
                      </div>
                      <div className="featured-bottom">
                        <div className="job-meta">
                          <span>
                            <i className="fa fa-map-marker-alt"></i>Czech
                            Republic
                          </span>
                          <span>
                            <i className="fa fa-filter"></i>Building &
                            Construction Jobs
                          </span>
                        </div>
                        <div className="job-time">
                          <span className="danger-color">Full time</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* featured-imagebox end */}
                </div>

                <div className="col-lg-12">
                  <Row className="my-4">
                    <Col>
                      <Pagination className="justify-content-center">
                        <Pagination.Item
                          as="button"
                          onClick={() => setActive(Math.max(1, active - 1))}
                        >
                          Prev
                        </Pagination.Item>
                        {[...Array(totalPages).keys()].map((page) => (
                          <Pagination.Item
                            key={page + 1}
                            active={page + 1 === active}
                            onClick={() => setActive(page + 1)}
                          >
                            {page + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Item
                          as="button"
                          onClick={() =>
                            setActive(Math.min(totalPages, active + 1))
                          }
                        >
                          Next
                        </Pagination.Item>
                      </Pagination>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* row end */}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer></Footer>
    </main>
  );
}

export default EuropeWorkVisa;
