import React, { Component } from "react";
import Slider from "react-slick";
import ProgressBar from "react-animated-progress-bar";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
import { Footer } from "../components/layout/Footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CountUp from "react-countup";
import Img from "./Img";
import { Helmet } from "react-helmet";

export class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      rows: 1,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 778,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="site-main">
        <Header />

        {/* PageHeader */}
        <PageHeader title="services" breadcrumb="services" />
        {/* PageHeader end */}
        <Helmet>
          <title>Our Services - Workwell Solutions BD</title>
          <meta
            name="description"
            content="Explore the diverse range of services offered by Workwell Solutions BD, your trusted partner for European job opportunities and work visas."
          />
          <meta
            name="keywords"
            content="Workwell, Workwell Solutions, Workwell Solutions BD, Vandary, Vandary Faruk, Europe Work Visa, Romania Work Visa, Poland Work Visa, Work Permit Europe, Malta Job Visa, Croatia Work Visa"
          />
          <meta
            name="description"
            content="Explore opportunities for Europe Work Visa with WorkWell Solutions BD. Learn about Romania and Poland Work Visa, Work Permit in Europe, Malta and Croatia Job Visa and more."
          />
          <meta
            name="keywords"
            content="workwell, workwell solutions, workwell solutions bd, workwellsolutionsbd, vandary, vandary faruk, europe work visa, romania work visa, poland work visa, work permit europe, malta job visa, croatia work visa, job in europe, bangladesh europe job, estonia job, workvisaeurope, jobsineurope, romania job visa, restaurant job, agriculture job visa europe, italy job visa, italy agriculture visa, work well solution bd, workwellsolution, workwellsolutionsbd, skyasiabd, skyasia, sky asia, job visa europe"
          />
        </Helmet>
        {/* services-section */}
        <section
          className="ttm-row services-section bg-img1 bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix"
          style={{ backgroundImage: "url(images/bg-image/row-bgimage-1.png)" }}
        >
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-11">
                {/* section title */}
                <div className="section-title style2 mb-0">
                  <div className="title-header">
                    <h3>
                      Classic{" "}
                      <span className="text-theme-SkinColor">Service</span>
                    </h3>
                    <h2 className="title">We Understand Needs</h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      At Workwell Solutions BD, we specialize in{" "}
                      <span className="text-theme-SkinColor">identifying</span>{" "}
                      in roles that align with their skills and aspirations. Our
                      proven methodologies ensure the right fit for both the
                      candidate and the company. Employs meticulous strategies
                      to match top-tier talent with suitable roles in Europe...
                    </p>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* Slider */}
            <Slider
              className="row slick_slider slick-arrows-style2 pt-20 mb_10"
              {...slick_slider}
              slidesToShow={2}
              rows={2}
              arrows={true}
              autoplay={false}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: { slidesToShow: 2, slidesToScroll: 2 },
                },
                {
                  breakpoint: 575,
                  settings: { slidesToShow: 1, slidesToScroll: 1 },
                },
              ]}
            >
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-services style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      src="images/services/transport-hover.png"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Workforce Optimization
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Through data-driven analysis, we optimize workforce
                        productivity and efficiency, ensuring that both
                        businesses and employees thrive.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-services style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      src="images/services/finance-hover.png"
                      alt="ইউরোপ কাজের ভিসা"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Strategic Employment Solutions
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Beyond traditional recruitment, we offer bespoke
                        employment solutions tailored to the unique requirements
                        of modern businesses. This includes contract staffing,
                        project-based hiring, and executive search.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-services style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      src="images/services/technology-hover.png"
                      alt="romania work visa "
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Skill Development
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        To bridge the skill gap, we offer training programs with
                        the demands of the current job market. we ensure our
                        candidates are job-ready.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-services style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      src="images/services/production-hover.png"
                      alt="work visa europe"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Visa & Work Permit Assistance
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Navigating the complexities of the visa application
                        process. Our dedicated team ensures a seamless
                        experience, assisting clients in securing work permits
                        and visas for European countries.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-services style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      src="images/services/transport-hover.png"
                      alt="workwell soluations bd"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Post-Placement Support
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Our relationship with candidates doesn't end once
                        they're placed. We offer ongoing support to ensure
                        smooth integration into new roles and work environments.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-services style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      src="images/services/transport-hover.png"
                      alt="romania visa"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/services_details"}>
                          Talent Acquisition
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        {" "}
                        Streamlined recruitment process, reduced time-to-hire,
                        access to a vast talent pool, and improved retention
                        rates.
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
            </Slider>
            {/* Slider end */}
          </div>
        </section>
        {/* services-section end */}
        <h1 className="d-none">
          Comprehensive Services for European Employment
        </h1>
        <section className="talent-acquisition d-none">
          <h2>Expert Talent Acquisition Services</h2>
          <p>
            Workwell Solutions BD, led by Vandary Faruk, employs meticulous
            strategies to match top-tier talent with suitable roles in Europe...
          </p>
        </section>

        <section className="workforce-optimization d-none">
          <h2>Optimize Your Workforce with Workwell</h2>
          <p>
            Achieve unparalleled productivity and efficiency through our
            data-driven workforce optimization strategies...
          </p>
          <button>Improve Your Workforce</button>
        </section>
        {/* cta-section */}
        <section
          className="ttm-row cta-section bg-img7 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix"
          style={{
            backgroundImage:
              'url("https://www.workwellsolutionsbd.com/images/images/euf.jpg")',
          }}
        >
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-8 m-auto row-title">
                {/* section-title */}
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      <span className="text-theme-SkinColor">
                        More than 50,000 happy client
                      </span>
                    </h3>
                    <h2 className="title">
                      Get In Touch With Recruitment Staffing
                      <span className="text-theme-SkinColor"> Agency!</span>
                    </h2>
                  </div>
                </div>
                {/* section-title end */}
                <div className="text-center pt-10">
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                    href={process.env.PUBLIC_URL + "/Contact_us"}
                  >
                    contact us
                  </a>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* cta-section end */}

        {/* blog-section */}
        <section className="ttm-row blog-section clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      Extend{" "}
                      <span className="text-theme-SkinColor">Knowledge</span>
                    </h3>
                    <h2 className="title">Latest News & Articles</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row end */}
            {/* Slider */}
            <Slider
              className="row slick_slider mb_10"
              {...slick_slider}
              slidesToShow={3}
              arrows={false}
              autoplay={false}
            >
              <div className="col-md-12">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/images/req01.jpg"
                    />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          18
                          <span className="entry-month entry-year">Mar</span>
                        </time>
                      </span>
                    </div>
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        business
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          Are meetings falling to flat? Try these 10 tips
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Whatever you do in planning, facilitating. Goal should
                        revolve support{" "}
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/Services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/images/req02.jpg"
                    />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          18
                          <span className="entry-month entry-year">Mar</span>
                        </time>
                      </span>
                    </div>
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        business
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          Are You Moving Businesses To Another State?
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        A business leader must consider issues beyond choosing a
                        moving!
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/Services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/images/req04.jpg"
                    />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          18
                          <span className="entry-month entry-year">Mar</span>
                        </time>
                      </span>
                    </div>
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        business
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          Shame: Does It Fine to Fit In The Workplace?
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        This can be innate or external and results can be
                        surprising performance
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/Services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
              <div className="col-md-12">
                {/* featured-imagebox-post */}
                <div className="featured-imagebox featured-imagebox-post style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/images/req06.jpg"
                    />

                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          18
                          <span className="entry-month entry-year">Mar</span>
                        </time>
                      </span>
                    </div>
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        business
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/Services_details"}>
                          How to Handle Negativity in Former People?
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Whatever you do in planning, facilitating. Goal should
                        revolve support
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                      href={process.env.PUBLIC_URL + "/Services_details"}
                    >
                      read more!
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-post end */}
              </div>
            </Slider>
            {/* Slider end */}
          </div>
        </section>
        {/* blog-section end */}

        {/* action-section */}
        <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-md-flex align-items-center justify-content-between">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content style2">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                        <i className="flaticon flaticon-recruitment-5"></i>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>Incredible Recruitment & Staffing Agency</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          We have over 30 years experience oft Business porro
                          qusquam dol ipsum quia dolor sit amet.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                    href="/contact-us"
                  >
                    Hiring Now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* action-section end */}

        <Footer />
      </div>
    );
  }
}

export default Services;
