import React, { Component } from "react";
import Slider from "react-slick";
import Img from "../../pages/Img";
import "./Home.css";
const images = [
  process.env.PUBLIC_URL + "/images/images/euf.jpg",
  process.env.PUBLIC_URL + "/images/images/eus2.jpg",
  process.env.PUBLIC_URL + "/images/images/eutwin.jpg",
  process.env.PUBLIC_URL + "/images/images/eus3.jpg",
  process.env.PUBLIC_URL + "/images/images/eus9.jpg",
  process.env.PUBLIC_URL + "/images/images/euflag.jpg",
  process.env.PUBLIC_URL + "/images/images/eus7.jpg",
  process.env.PUBLIC_URL + "/images/images/eus8.jpg",
];
export class Banner extends Component {
  state = {
    show: false,
  };

  state = {
    show: false,
    currentImageIndex: 0,
  };

  changeImage = () => {
    this.setState((prevState) => ({
      currentImageIndex: (prevState.currentImageIndex + 1) % images.length,
    }));
  };

  componentDidMount() {
    this.sliderInterval = setInterval(this.changeImage, 5000); // Change image every 9000ms (9 seconds)
  }

  componentWillUnmount() {
    clearInterval(this.sliderInterval);
  }
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Slider
        className="slick_slider banner_slider banner_slider_3"
        {...slick_slider}
        slidesToShow={1}
        autoplay={false}
        fade="true"
      >
        <div className="slide bg-layer">
          {images.map((image, index) => (
            <div
              key={index}
              className="slide_img"
              style={{
                backgroundImage: `url(${image})`,
                opacity: index === this.state.currentImageIndex ? 1 : 0,
                zIndex: index === this.state.currentImageIndex ? 1 : 0,
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                transition: "opacity 1s ease-in-out",
              }}
            ></div>
          ))}
          <div
            className="slide__content pb-50 pb-xl-0 pt-130 pt-xl-0 "
            style={{
              zIndex: 300,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-8">
                  <div className="slide__content--headings ttm-textcolor-white">
                    <h2 className="animated animate__fadeInDown">
                      Workwell Solutions BD{" "}
                    </h2>
                    <h3 className="animated animate__fadeInDown">
                      {" "}
                      Your Trusted Partner for European Job Opportunities{" "}
                    </h3>
                    <div className="mt-20 mb-30 mb-md-15">
                      <p
                        className="animated animate__fadeInDown"
                        className="d-none d-md-block "
                      >
                        Workwell Solutions BD excels in securing European work
                        permits and visas, transforming lives with dream jobs
                        abroad. We're dedicated to client satisfaction and
                        boosting our country's economy through genuine work
                        visas and outstanding service.
                      </p>
                    </div>
                    <div
                      className="animated animate__fadeInUp"
                      data-delay="1.4"
                    >
                      <a
                        className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                        href={process.env.PUBLIC_URL + "/contact_us"}
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-none d-xl-block text-center position-relative">
                    <div className="z-index-1 position-relative objectfit1">
                      <Img
                        className="rounded-circle box-shadow m-auto img-fuild  objectfit"
                        src="images/slides/pexels-kindel-media-7688161_332x332.jpg"
                      ></Img>
                    </div>
                    <div className="cmp-logo one">
                      <Img
                        class="img-fluid"
                        src="images/slides/logo1.png"
                      ></Img>
                    </div>
                    <div className="cmp-logo two">
                      <Img
                        className="img-fluid"
                        src="images/slides/logo2.png"
                      />
                    </div>
                    <div className="cmp-logo three">
                      <Img
                        className="img-fluid"
                        src="images/slides/logo3.png"
                      />
                    </div>
                    <div className="cmp-logo four">
                      <Img
                        className="img-fluid"
                        src="images/slides/logo4.png"
                      />
                    </div>
                    <div className="cmp-logo five">
                      <Img
                        className="img-fluid"
                        src="images/slides/logo5.png"
                      />
                    </div>
                    <div className="arrow-one">
                      <Img src="images/slides/arrow-1.png" />
                    </div>
                    <div className="arrow-two">
                      <Img src="images/slides/arrow-2.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}

export default Banner;
