import React, { Component } from "react";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
import { Footer } from "../components/layout/Footer";
import Img from "./Img";

export class Contact_us extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />

        {/* PageHeader */}
        <PageHeader title="Contact us" breadcrumb="Contact 01" />
        {/* PageHeader end */}

        {/* grid-section */}
        <section className="ttm-row grid-section clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-3 col-md-6"></div>
              <div className="col-lg-6 col-md-6">
                <div className="featured-imagebox box-shadow">
                  <div className="featured-thumbnail">
                    <Img
                      className="img-fluid"
                      alt="Workwell Solutions Bd "
                      src="images/slides/arrow-2.png"
                    />
                  </div>
                  <div className="featured-content p-30">
                    <div className="featured-title">
                      <h3>
                        <a href={process.env.PUBLIC_URL + "/"}>Dhaka</a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                        <li className="pb-5">
                          <i className="fas fa-map-marker-alt"></i>
                          <div className="ttm-list-li-content">
                            Addres:
                            <span>
                              {" "}
                              <a
                                href="https://www.google.com/maps/search/?api=1&query=23.749059,90.347346"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {" "}
                                Bosila Model Town, Mohammadpur, Dhaka,
                                Bangladesh
                              </a>
                            </span>
                          </div>
                        </li>
                        <li className="pb-5">
                          <i className="fas fa-phone-alt"></i>
                          <div className="ttm-list-li-content">
                            Call Us:
                            <a href="tel:+8801988734762">+8801988734762</a>
                          </div>
                        </li>
                        <li>
                          <i className="fa fa-envelope"></i>
                          <div className="ttm-list-li-content">
                            Email:
                            <a href="mailto:info@workwellsolutionsbd.com">
                              info@workwellsolutionsbd.com
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6"></div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* grid-section end */}

        {/* map-section */}
        <section className="ttm-row map-section bg-theme-GreyColor clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                {/* section title */}
                <div className="section-title title-style-center_text pb-15">
                  <div className="title-header">
                    <h3>
                      get in <span className="text-theme-SkinColor">touch</span>
                    </h3>
                    <h2 className="title">Feel Free To Contact</h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
              <div className="col-lg-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d49133.565176619624!2d90.3530713490523!3d23.751869302082948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjPCsDQ0JzU2LjYiTiA5MMKwMjAnNTAuNSJF!5e0!3m2!1sbn!2sbd!4v1699895012309!5m2!1sbn!2sbd"
                  height="530"
                  width="100%"
                ></iframe>
              </div>
              <div className="col-lg-6">
                <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-WhiteColor z-index-2 p-40 p-lg-30 mt-lg-30">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content">
                    <form id="contact_form" className="contact_form wrap-form">
                      <div className="row ttm-boxes-spacing-10px">
                        <div className="col-md-12 ttm-box-col-wrapper">
                          <label>
                            <input
                              name="name"
                              type="text"
                              placeholder="Your Name"
                              required="required"
                            />
                          </label>
                        </div>
                        <div className="col-md-6 ttm-box-col-wrapper">
                          <label>
                            <input
                              name="email"
                              type="text"
                              placeholder="Your Email"
                              required="required"
                            />
                          </label>
                        </div>
                        <div className="col-md-6 ttm-box-col-wrapper">
                          <label>
                            <input
                              name="subject"
                              type="text"
                              placeholder="Subject"
                              required="required"
                            />
                          </label>
                        </div>
                        <div className="col-md-12 ttm-box-col-wrapper">
                          <label>
                            <input
                              name="phone"
                              type="text"
                              placeholder="Phone Number"
                              required="required"
                            />
                          </label>
                        </div>
                        <div className="col-md-12 ttm-box-col-wrapper">
                          <label>
                            <textarea
                              name="message"
                              rows="7"
                              placeholder="Message"
                              required="required"
                            ></textarea>
                          </label>
                        </div>
                      </div>
                      <button
                        className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                            ttm-btn-color-skincolor w-100"
                        type="submit"
                      >
                        send a message!
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* map-section end */}

        {/* action-section */}
        <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-md-flex align-items-center justify-content-between">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box icon-align-before-content style2">
                    <div className="featured-icon">
                      <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                        <i className="flaticon flaticon-recruitment-5"></i>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>Workwell Solutions BD</h3>
                      </div>
                      <div className="featured-desc">
                        <p>– Where Dreams Meet Opportunities.</p>
                      </div>
                    </div>
                  </div>
                  {/* featured-icon-box end */}
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                    href={process.env.PUBLIC_URL + "/contact_us"}
                  >
                    Hiring Now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* action-section end */}

        <Footer />
      </div>
    );
  }
}

export default Contact_us;
