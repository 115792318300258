import React, { useState, useEffect } from "react";
import HappyClientsBackgroundImage1 from "../images/slides/05.jpg";
import HappyClientsBackgroundImage2 from "../images/slides/02.jpg";
import HappyClientsBackgroundImage3 from "../images/slides/06.jpg";
import HappyClientsBackgroundImage4 from "../images/slides/07.jpg";

const sliderStyle = {
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "100%",
};

const slideStyle = (image, isActive) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  opacity: isActive ? 1 : 0,
  transition: "opacity 1s ease",
  backgroundImage: `url(${image})`,
  backgroundSize: "cover",
});
const ClientsSlider = () => {
  const images = [
    HappyClientsBackgroundImage1,
    HappyClientsBackgroundImage2,
    HappyClientsBackgroundImage3,
    HappyClientsBackgroundImage4,
  ];

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrent(current === images.length - 1 ? 0 : current + 1);
    }, 3000); // Change the duration between slides (in milliseconds)

    return () => clearTimeout(timer);
  }, [current, images.length]);

  return (
    <div style={sliderStyle} className="ttm-col-wrapper-bg-layer ttm-bg-layer">
      {images.map((image, index) => (
        <div
          key={index}
          className="ttm-col-wrapper-bg-layer ttm-bg-layer"
          style={slideStyle(image, index === current)}
        ></div>
      ))}
    </div>
  );
};

export default ClientsSlider;
